<template>
  <div class="view-upload-the-item">
    <div class="top-upload">
      <div class="item-title">上传拍品图片</div>
      <div class="upload-item">
        <div class="upload-item-left">
          <div class="upload-item-left-1">
            <img src="../../../assets/images/yasuobao@2x.png" alt="" />
          </div>
          <div class="upload-item-left-2 te-le">
            <p>1.拍品图片以Zip压缩包格式上传.</p>
            <p>
              2.图片命名需遵循以下原则：Lot_图片编号.jpg，如：101_1.jpg、102_2.jpg
            </p>
            <p style="color:red">3.图片格式限制为JPG、PNG、JPEG格式</p>
            <p style="color:red">
              4.上传的图片需要保证拍品清晰，图片长宽需要大于1000像素，每张图限制大小为3M
            </p>
          </div>
        </div>
        <el-upload class="upload-demo" ref="upload" action="" :http-request="httpRequest" multiple accept=".zip"
          :on-change="handleFile">
          <el-button size="small" type="primary" @click.stop="judgeHandle">点击上传</el-button>
        </el-upload>
      </div>
      <a href="javascript:;" class="go-next" @click="next">下一步</a>
    </div>
    <div class="middle-table">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="uploadTime" label="上传时间" width="180">
        </el-table-column>
        <el-table-column prop="fileName" label="文件名" width="180">
        </el-table-column>
        <el-table-column prop="fileType" label="类型" width="180">
        </el-table-column>
        <el-table-column prop="status" label="状态"> </el-table-column>
        <el-table-column prop="count" label="总数(条)"> </el-table-column>
        <el-table-column prop="successCount" label="成功(条)">
        </el-table-column>
        <el-table-column prop="errCount" label="失败(条)"> </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text">
              <a href="javascript:;" class="red" @click="errorLook(scope.row.errList||[])">查看</a>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="错误信息" :visible.sync="dialogVisible" width="40%">
      <el-table :data="newArry" style="width: 100%">
        <el-table-column prop="Lot" label="Lot号" width="180">
        </el-table-column>
        <el-table-column prop="ErrMsg" label="错误详情"> </el-table-column>
      </el-table>
      <div class="el-pag-ination">
        <el-pagination background @current-change="handleCurrentChange" :page-size="pageSize" :total="total"
          :current-page="currentPage" layout="total, prev, pager, next">
        </el-pagination>
      </div>
    </el-dialog>
    <div class="progress" v-if="textInside">
      <div class="box-progress">
        <span style="padding-right:7px">{{ elProgress }}</span>
        <i v-if="this.percentage === 100.0" style="font-size:17px" class="el-icon-loading"></i>
        <div class="stroke">
          <el-progress :text-inside="true" :stroke-width="26" :percentage="percentage"></el-progress>
        </div>
      </div>
    </div>
    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%" center>
      <div class="centerTds">
        <img src="../../../assets/images/tixingx@2x.png" alt="" />
        <span class="wx-tx">温馨提示</span>
        <span class="wx-wz">再次上传将会覆盖相同名称的图片文件，您是否确认继续上传？</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="dialog-footer-1" @click="centerDialogVisible = false">
          取消上传
        </button>
        <button class="dialog-footer-2" @click="okUpload()">确认上传</button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import {
    uploadFile,
    uploadItemImageData,
    liveUnzip
  } from '../../../api/index'
  import OSS from 'ali-oss'
  import utils from '../../../utils/utils'
  import axios from 'axios'
  import qs from 'qs'
  const uploadUrl = 'https://upload.easyebid.com' //正式
  //const uploadUrl = 'https://upload.test.easyebid.com' //测试
  export default {
    name: 'Uploadpictureitem',
    data() {
      return {
        uploadItemDataList: {
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          auctionId: Number(localStorage.getItem('auctionId')),
          auctionHouseId: Number(localStorage.getItem('accessId')),
          filePath: ''
        },
        tableData: [],
        dialogVisible: false,
        newArry: [],
        currentPage: 1,
        pageSize: 10,
        marking: 4,
        centerDialogVisible: false,
        offBtn: false,
        time: null,
        tempCheckpoint: null,
        percentage: 0, // 进度条百分比
        uploadName: '', // Object所在Bucket的完整路径
        upload: null,
        textInside: false,
        total: 0,
        elProgress: '上传进度'
      }
    },
    destroyed() {
      clearTimeout(this.time)
    },
    created() {
      if (this.tableData === []) {
        this.tableData = JSON.parse(localStorage.getItem('tableData'))
      }
      if (
        this.$route.query.reEstablishId === '4' ||
        this.$route.query.reEstablishId === 4
      ) {
        this.uploadItemDataList.auctionId = Number(this.$route.query.eventsId)
      }
    },
    methods: {
      // 下一步
      next() {
        if (this.uploadItemDataList.filePath === '') {
          this.$message.error('请上传拍品信息')
          return
        }
        this.$emit('picture', this.marking)
      },
      uuid() {
        let s = [];
        const hexDigits = '0123456789abcdef';
        for (var i = 0; i < 36; i++) {
          s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = '4';
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);
        s[8] = s[13] = s[18] = s[23] = '-';
        return s.join('');
      },
      handleFetchQueue(dataArr, max, fileSize, callback) {
        const dataCount = dataArr.length; //请求个数-每个接口传参
        const requestsQueue = [];
        const results = []; //每次请求完成结果
        let i = 0;
        let progressSize = 0
        this.percentage = 0 //再次上传时初始为0
        const handleRequest = (query) => {
          const req = axios({
            method: 'post',
            url: `${uploadUrl}/v1/auction/uploadZipFile`,
            data: query
          }).then(res => {
            if (res.data.code === 1000) {
              progressSize += res.data.data.fileSize //当前已上传多少b
              this.percentage = Number((progressSize / fileSize * 100).toFixed(2)) //百分比进度
              if (this.percentage === 100.00) {
                this.elProgress = '上传成功，数据处理中'
              }
              //处理循环请求
              const len = results.push(res); //已请求并成功的接口个数
              if (len < dataCount && i + 1 < dataCount) {
                requestsQueue.shift(); //删除数组第一个
                handleRequest(dataArr[++i])
              } else if (len === dataCount) {
                'function' === typeof callback && callback(results)
              }
            } else {
              this.$message.error(res.data.msg)
              results.push(res)
            }
          }).catch(e => {
            results.push(e)
          })
          if (requestsQueue.push(req) < max) {
            handleRequest(dataArr[++i])
          }
        };
        handleRequest(dataArr[i])
      },
      async handleFile(File) {
        const fileSize = File.size
        let progressSize = 0
        const chunkSize = 1 * 1024 * 1024; //定义分片的大小Mb
        const fileUid = this.uuid() //唯一id
        const chunkCount = Math.ceil(fileSize / chunkSize) //计算当前选择文件需要的分片数量
        let queryArr = [] //所有分片数据
        //分片参数搜集
        for (var i = 0; i < chunkCount; i++) {
          let start = i * chunkSize //分片开始位置
          let end = Math.min(fileSize, start + chunkSize) //分片结束位置
          let _chunkFile = File.raw.slice(start, end) //分片文件
          // 通过await实现顺序上传
          let formdata = new FormData()
          formdata.append('fileName', fileUid)
          formdata.append('fileIndex', i)
          formdata.append('uploadId', fileUid)
          formdata.append('file', _chunkFile)
          queryArr.push(formdata)
        }
        const max = queryArr.length < 6 ? queryArr.length : 6 //最高并发数
        //开始分片上传
        this.elProgress = '上传进度'
        this.textInside = true
        this.handleFetchQueue(queryArr, max, fileSize, res => {
          //所有分片接口请求完成再合并
          const mergeObj = {
            fileName: fileUid,
            fileIndexTotal: chunkCount,
            uploadId: fileUid,
            fileSize: fileSize
          }
          axios({
            method: 'post',
            url: `${uploadUrl}/v1/auction/mergeZipFile`,
            data: qs.stringify(mergeObj),
          }).then(res => {
            if (res.data.code === 1000) {
              this.uploadItemDataList.filePath = res.data.data.filePath
              this.liveUnzip(res.data.data.filePath)
            } else {
              this.$message.error(res.data.msg)
            }
          })
        })
      },
      // async handleFile (event) {
      //   var uploadHost = 'https://pic.easyebid.com'
      //   await this.uploadFile()
      //   var client = new OSS({
      //     region: 'oss-cn-beijing',
      //     accessKeyId: this.ossToken.accessKeyId,
      //     accessKeySecret: this.ossToken.accessKeySecret,
      //     bucket: 'yideonline',
      //     stsToken: this.ossToken.securityToken
      //   })
      //   var file = event.raw
      //   const type = file.name.split('.')
      //   const len = type.length
      //   var timestamp = Date.parse(new Date())
      //   const fileName = 'yide' + timestamp + '.' + type[len - 1]
      //   // object-name可以自定义为文件名（例如file.txt）或目录（例如abc/test/file.txt）的形式，实现将文件上传至当前Bucket或Bucket下的指定目录。
      //   const result = await client.multipartUpload(`zipfile/${fileName}`, file, {
      //     headers: {
      //       'Content-Disposition': 'inline',
      //       'Content-Type': type[len - 1] // 注意：根据图片或者文件的后缀来设置，我试验用的‘.png’的图片，具体为什么下文解释
      //     },
      //     progress: (p, checkpoint) => {
      //       this.tempCheckpoint = checkpoint
      //       this.upload = checkpoint.uploadId
      //       this.uploadName = checkpoint.name
      //       const num = (p * 100).toFixed(2)
      //       this.percentage = Number(num)
      //       if (this.percentage !== 0) {
      //         this.elProgress = '上传进度'
      //         this.textInside = true
      //       }
      //       if (this.percentage === 100.0) {
      //         this.elProgress = '上传成功，数据处理中'
      //       }
      //       // console.log(p, checkpoint, this.percentage, '---------uploadId-----------')
      //       // 断点记录点。浏览器重启后无法直接继续上传，您需要手动触发上传操作。
      //     },
      //     parallel: 10,
      //     partSize: 500 * 1024,
      //     meta: { year: 2020, people: 'dev' },
      //     mime: event.raw.type
      //   })
      //   // console.log(result, this.percentage, 'result= 切片上传完毕=')
      //   // var timestamp = Date.parse(new Date())
      //   // var file = event.raw
      //   // const [textName, type] = file.name.split('.')
      //   // const fileName = 'yide' + timestamp + '.' + type
      //   // const result = await client.put(`zipfile/${fileName}`, file)
      //   if (result.res.statusCode === 200) {
      //     this.uploadItemDataList.filePath = `${uploadHost}/${result.name}`
      //     // this.uploadItemData()
      //     this.liveUnzip()
      //   }
      // },
      okUpload() {
        this.offBtn = true
        this.centerDialogVisible = false
        this.$refs.upload.$refs['upload-inner'].handleClick()
      },
      // 点击上传按钮
      judgeHandle() {
        if (this.offBtn === false) {
          if (this.tableData.length !== 0) {
            this.centerDialogVisible = true
          } else {
            this.$refs.upload.$refs['upload-inner'].handleClick()
          }
        }
      },
      // 解压zip包
      liveUnzip(filePath) {
        axios({
          method: 'post',
          url: `${uploadUrl}/v1/auction/unZipFile`,
          data: qs.stringify({
            filePath: filePath
          }),
        }).then(res => {
          if (res.data.code === 1000) {
            this.uploadItemData()
          } else {
            this.$message.error(res.data.msg)
          }
        })
      },
      // 解压zip包
      // async liveUnzip() {
      //   const res = await liveUnzip(this.uploadItemDataList)
      //   if (res.code === 0) {
      //     this.uploadItemData()
      //   } else {
      //     this.$message.error(res.msg)
      //   }
      // },
      // 上传Zip
      uploadItemData() {
        axios({
          method: 'post',
          url: `${uploadUrl}/v1/auction/uploadItemImageData`,
          data: qs.stringify({
            auctionId: this.uploadItemDataList.auctionId,
            filePath: this.uploadItemDataList.filePath
          }),
        }).then(res => {
          if (res.data.code === 1000) {
            this.$message.success(res.data.msg)
            const uploadTime = {
              uploadTime: utils.getCurrentTime(),
              status:res.data.msg
            }
            const data = Object.assign(res.data.data, uploadTime)
            this.tableData.push(data)
            localStorage.setItem('tableData', JSON.stringify(this.tableData))
            if (this.offBtn === true) {
              this.offBtn = false
            }
            this.textInside = false
          } else {
            this.$message.error(res.data.msg)
            if (this.offBtn === true) {
              this.offBtn = false
            }
          }
        })
      },
      // 上传Zip
      // async uploadItemData() {
      //   const res = await uploadItemImageData(this.uploadItemDataList)
      //   if (res.code === 0) {
      //     if (res.data.resultCode === 103) {
      //       if (res.data.status === '上传成功') {
      //         // this.$message.success('')
      //         this.$message.success(res.data.status)
      //       } else {
      //         this.$message.error(res.data.status)
      //       }
      //     } else {
      //       this.$message.success('上传成功')
      //     }

      //     const uploadTime = {
      //       uploadTime: utils.getCurrentTime()
      //     }
      //     const data = Object.assign(res.data, uploadTime)
      //     this.tableData.push(data)
      //     localStorage.setItem('tableData', JSON.stringify(this.tableData))
      //     // this.tableData = res.data.uploadItemErrList
      //     if (this.offBtn === true) {
      //       this.offBtn = false
      //     }
      //     this.textInside = false
      //   } else {
      //     this.$message.error('上传失败')
      //     // this.textInside = false

      //     // this.uploadItemDataList.filePath = ''
      //     if (this.offBtn === true) {
      //       this.offBtn = false
      //     }
      //   }
      // },
      // oss token
      async uploadFile() {
        const res = await uploadFile()
        if (res.code === 0) {
          this.ossToken = res.data
        } else {
          this.$message.error(res.msg)
        }
      },
      random_string(len) {
        len = len || 32
        var chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678'
        var maxPos = chars.length
        var pwd = ''
        for (let i = 0; i < len; i++) {
          pwd += chars.charAt(Math.floor(Math.random() * maxPos))
        }
        return pwd
      },
      httpRequest() {},
      // 查看错误条数
      errorLook(list) {
        this.dialogVisible = true
        this.tableDataT = list
        this.currentPage = 1
        this.getResultsData()
      },
      // 前端自己分页
      getResultsData() {
        // this指向改一下
        var that = this
        var list = that.tableDataT // 后端回来表格的数据
        // 渲染的数据newArry赋值
        this.newArry = list.filter(
          (item, index) =>
          index < that.currentPage * that.pageSize &&
          index >= that.pageSize * (that.currentPage - 1)
        ) // 根据页数显示相应的内容
        this.total = list.length
      },
      // 分页
      handleCurrentChange(page) {
        this.currentPage = page
        this.getResultsData()
      },
      handleSizeChange(size) {
        this.pageSize = size
        this.getResultsData()
      }
    }
  }
</script>
<style lang="less" scoped>
  .view-upload-the-item {
    padding: 0 40px;

    /deep/ .el-progress-bar__inner {
      background-color: #cda156;
    }

    .progress {
      width: 100%;
      height: 1080px;
      background: rgba(0, 0, 0, 0.18);
      padding: 0;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000;

      .box-progress {
        width: 900px;
        height: 201px;
        background: #fff;
        z-index: 1001;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: 0 50px;
        padding-top: 20px;

        span {
          text-align: center;
          font-size: 18px;
          font-weight: 600;
          color: #333;
        }

        .stroke {
          margin-top: 70px;
        }
      }
    }

    .centerTds {
      display: flex;
      flex-direction: column;
      align-items: center;

      .wx-tx {
        color: #cda156;
        font-size: 24px;
        padding: 10px 0;
      }

      .wx-wz {
        font-size: 16px;
        font-weight: 400;
        color: #666;
      }

      img {
        width: 100px;
      }
    }

    .dialog-footer {
      .dialog-footer-1 {
        width: 180px;
        height: 44px;
        background: #fff;
        border-radius: 8px;
        border: 1px solid #cda156;
        text-align: center;
        line-height: 44px;
        color: #cda156;
        font-size: 18px;
      }

      .dialog-footer-2 {
        width: 180px;
        height: 44px;
        background: #f5edde;
        border-radius: 8px;
        border: none;
        text-align: center;
        line-height: 44px;
        color: #6e5121;
        font-size: 18px;
        margin-left: 20px;
      }
    }

    .top-upload {
      .item-title {
        text-align: left;
        font-size: 20px;
        font-weight: 600;
        color: #333;
        margin-bottom: 10px;
      }

      .upload-item {
        background: #faf4e5;
        padding: 34px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .upload-item-left {
          display: flex;
          align-items: center;
          justify-content: center;

          .upload-item-left-1 {
            width: 100px;

            img {
              width: 100%;
            }
          }

          .te-le {
            text-align: left;
          }

          .upload-item-left-2 {
            margin-left: 30px;
            font-size: 16px;

            p {
              margin-bottom: 10px;
              color: #333;
            }

            a {
              text-decoration: underline;
              color: #cda156;
            }
          }
        }
      }

      /deep/ .el-upload-list {
        display: none;
      }

      /deep/ .el-button--primary {
        width: 140px;
        border-radius: 8px;
        background: #cda156;
        height: 44px;
        text-align: center;
        border: none;
        color: #fff;
        border-radius: 8px;
        display: block;
        font-size: 16px;
        opacity: 0.7;
      }

      .go-next {
        width: 280px;
        height: 44px;
        background: #cda156;
        border-radius: 8px;
        color: #6e5121;
        line-height: 44px;
        text-align: center;
        font-size: 18px;
        opacity: 0.7;
        display: block;
        margin: 20px auto 0 auto;
      }
    }

    /deep/ .el-table th.el-table__cell {
      background: #f5edde;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #cda156;
      color: #fff;
    }

    .el-pag-ination {
      margin-top: 20px;
      text-align: right;
      padding-right: 40px;
    }

    .middle-table {
      // padding: 0 20px;
      margin-top: 20px;

      /deep/ .el-table th.el-table__cell {
        background: rgba(245, 234, 204, 0.5);
      }

      /deep/ .el-table__header-wrapper {
        border-radius: 29px 29px 0px 0px !important;
      }

      /deep/ .el-table thead {
        color: #333333;
        font-size: 16px;
      }

      .red {
        color: #cda156;
        font-size: 16px;
        text-decoration: underline;
      }
    }
  }
</style>
