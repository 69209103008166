<template>
  <div class="view-bill-setting">
    <el-form :model="billSettingData" :rules="rules" ref="billSettingData" label-width="170px" class="demo-ruleForm">
      <el-form-item label="付款方式" prop="paymentMethod">
        <el-input type="textarea" v-model="billSettingData.paymentMethod" placeholder="请填写付款方式">
        </el-input>
      </el-form-item>
      <el-form-item label="邮寄方式" prop="mailingMethod">
        <el-input type="textarea" v-model="billSettingData.mailingMethod" placeholder="请填写邮寄方式">
        </el-input>
      </el-form-item>
    </el-form>
    <div class="submit-save">
      <button class="go-one" @click="setAuctionHouseOrderInformation('billSettingData')">保存</button>
    </div>
  </div>
</template>
<script>
import { setAuctionHouseOrderInformation } from '../../../../api/index'
export default {
  name: 'BillSetting',
  data () {
    return {
      billSettingData: {
        id: Number(localStorage.getItem('accessId')),
        paymentMethod: '',
        mailingMethod: '',
        x_user_id: Number(localStorage.getItem('accessId')) || 0,
        authorization: localStorage.getItem('accessToken')
      },
      rules: {
        paymentMethod: [
          { required: true, message: '请填写付款方式', trigger: 'change' },
          {
            min: 0,
            max: 2000,
            message: '长度不得超过2000个字',
            trigger: 'change'
          }
        ],
        mailingMethod: [
          { required: true, message: '请填写邮寄方式', trigger: 'change' },
          {
            min: 0,
            max: 2000,
            message: '长度不得超过2000个字',
            trigger: 'change'
          }
        ]
      }
    }
  },
  created () {
    if (
      localStorage.getItem('mailingMethod') !== '' ||
      localStorage.getItem('paymentMethod') !== ''
    ) {
      this.billSettingData.mailingMethod = localStorage.getItem('mailingMethod')
      this.billSettingData.paymentMethod = localStorage.getItem('paymentMethod')
    }
  },
  methods: {
    async setAuctionHouseOrderInformation (formName) {
      this.$refs[formName].validate(async valid => {
        if (valid) {
          if (
            this.billSettingData.paymentMethod.split(' ').join('').length ===
              0 ||
            this.billSettingData.mailingMethod.split(' ').join('').length === 0
          ) {
            this.$message.error('提交不能为空')
            return
          }
          const res = await setAuctionHouseOrderInformation(
            this.billSettingData
          )
          if (res.resultCode === 0) {
            this.$message.success('保存成功')
            localStorage.setItem('mailingMethod', res.mailingMethod)
            localStorage.setItem('paymentMethod', res.paymentMethod)
          } else {
            this.$message.error(res.msg)
          }
        } else {
          return false
        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.view-bill-setting {
  background: #fff;
  padding: 40px 358px;
  margin-bottom: 110px;
  /deep/ .el-form-item__label {
    font-weight: 600;
    color: rgb(56, 55, 55);
    font-size: 16px;
  }
  /deep/ .el-textarea__inner {
    width: 600px;
    height: 100px;
    resize: none;
    outline: none;
    border: 1px solid #bfc3c4;
    padding-left: 13px;
  }
  .textarea-box {
    width: 600px;
    .textarea {
      width: 100%;
      height: 140px;
      resize: none;
      outline: none;
      border: 1px solid #bfc3c4;
      padding-left: 13px;
    }
  }
  .submit-save {
    .go-one {
      width: 280px;
      color: #6e5121;
      font-size: 18px;
      opacity: 0.7;
      border-radius: 8px;
      background: #cda156;
      height: 44px;
      text-align: center;
      line-height: 44px;
      border: none;
      // float: left;
      margin-left: 80px;
    }
  }
}
</style>
