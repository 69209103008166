<template>
  <div class="view-upload-the-item">
    <div class="top-upload">
      <div class="item-title">上传拍品信息</div>
      <div class="upload-item">
        <div class="upload-item-left">
          <div class="upload-item-left-1">
            <img src="../../../assets/images/excel@2x.png" alt="">
          </div>
          <div class="upload-item-left-2">
            <p>批量上传拍品信息</p>
            <a
              href="https://pic.easyebid.com/pub/%E6%8B%8D%E5%93%81%E4%B8%8A%E4%BC%A0%E6%A8%A1%E6%9D%BF.xlsx">下载Excel模板</a>
          </div>
        </div>
        <el-upload class="upload-demo" action="" :http-request="httpRequest" multiple accept=".xlsx"
          :on-change="handleFile" ref="upload">
          <el-button size="small" type="primary" @click.stop="judgeHandle">点击上传</el-button>

        </el-upload>

      </div>
      <a href="javascript:;" class="go-next" @click="next">下一步</a>
    </div>
    <div class="middle-table">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="uploadTime" label="上传时间" width="180">
        </el-table-column>
        <el-table-column prop="fileName" label="文件名" width="180">
        </el-table-column>
        <el-table-column prop="fileType" label="类型" width="180">
        </el-table-column>
        <el-table-column prop="status" label="状态">
        </el-table-column>
        <el-table-column prop="count" label="总数(条)">
        </el-table-column>
        <el-table-column prop="successCount" label="成功(条)">
        </el-table-column>
        <el-table-column prop="errCount" label="失败(条)">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text">
              <a href="javascript:;" class="red" @click="errorLook(scope.row.uploadItemErrList)">查看</a>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-dialog title="错误信息" :visible.sync="dialogVisible" width="40%">
      <el-table :data="newArry" style="width: 100%">
        <el-table-column prop="lot" label="Lot号" width="180">
        </el-table-column>
        <el-table-column prop="errMsg" label="错误详情">
        </el-table-column>
      </el-table>
      <div class="el-pag-ination">
        <el-pagination background @current-change="handleCurrentChange" :page-size="pageSize" :total="total"
          :current-page="currentPage" layout="total, prev, pager, next">
        </el-pagination>
      </div>
    </el-dialog>

    <el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%" center>
      <div class="centerTds">
        <img src="../../../assets/images/tixingx@2x.png" alt="">
        <span class="wx-tx">温馨提示</span>
        <span class="wx-wz">再次上传将会覆盖之前上传的文件，您是否继续上传？</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <button class="dialog-footer-1" @click="centerDialogVisible = false">取消上传</button>
        <button class="dialog-footer-2" @click="okUpload()">确认上传</button>
      </span>
    </el-dialog>
  </div>

</template>
<script>
  import {
    uploadFile,
    uploadItemData
  } from '../../../api/index'
  import OSS from 'ali-oss'
  import utils from '../../../utils/utils'
  export default {
    name: 'UploadTheItem',
    data() {
      return {
        uploadItemDataList: {
          x_user_id: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          auctionId: Number(localStorage.getItem('auctionId')),
          auctionHouseId: Number(localStorage.getItem('accessId')),
          filePath: ''
        },
        tableData: [],
        dialogVisible: false,
        newArry: [],
        currentPage: 1,
        pageSize: 10,
        marking: 3,
        centerDialogVisible: false,
        offBtn: false,
        total: 0,
      }
    },
    created() {
      if (this.tableData === []) {
        this.tableData = JSON.parse(localStorage.getItem('tableData'))
      }
      if (this.$route.query.reEstablishId === '3' || this.$route.query.reEstablishId === 3) {
        this.uploadItemDataList.auctionId = Number(this.$route.query.eventsId)
      }
    },
    methods: {
      // 下一步
      next() {
        if (this.uploadItemDataList.filePath === '') {
          this.$message.error('请上传拍品信息')
          return
        }
        this.$emit('changeData', this.marking)
      },
      async handleFile(event) {
        var uploadHost = 'https://pic.easyebid.com'
        await this.uploadFile()
        var client = new OSS({
          region: 'oss-cn-beijing',
          accessKeyId: this.ossToken.accessKeyId,
          accessKeySecret: this.ossToken.accessKeySecret,
          bucket: 'yideonline',
          stsToken: this.ossToken.securityToken
        })

        var timestamp = Date.parse(new Date())
        var file = event.raw
        const [textName, type] = file.name.split('.')
        const fileName = 'yide' + timestamp + '.' + type
        const result = await client.put(`excel/${fileName}`, file)
        this.uploadItemDataList.filePath = `${uploadHost}/${result.name}`
        this.uploadItemData()
      },
      // 确认上传
      okUpload() {
        localStorage.setItem('isNew', 'false')
        this.offBtn = true
        this.centerDialogVisible = false
        this.$refs.upload.$refs['upload-inner'].handleClick()
        // this.handleFile()
        // this.uploadItemData()
      },
      // 点击上传按钮
      judgeHandle() {
        if (this.offBtn === false) {
          if (this.tableData.length !== 0) {
            this.centerDialogVisible = true
          } else {
            this.$refs.upload.$refs['upload-inner'].handleClick()
          }
        }
      },
      // 上传excel
      async uploadItemData() {
        this.uploadItemDataList.isNew = localStorage.getItem('isNew') !== 'false'
        const res = await uploadItemData(this.uploadItemDataList)
        if (res.code === 0) {
          if (res.data.resultCode === 103) {
            this.$message.error(res.data.resultMsg)
          } else {
            this.$message.success('上传成功')
            localStorage.removeItem('isNew')
          }
          const uploadTime = {
            uploadTime: utils.getCurrentTime()
          }
          const data = Object.assign(res.data, uploadTime)
          this.tableData.push(data)
          localStorage.setItem('tableData', JSON.stringify(this.tableData))
          if (this.offBtn === true) {
            this.offBtn = false
          }
          // this.tableData = res.data.uploadItemErrList
        } else {
          if (res.data.resultCode === 101) {
            this.uploadItemDataList.filePath = ''
            this.$message.error('最多可上传1000件拍品信息')
            return
          }
          if (this.offBtn === true) {
            this.offBtn = false
          }
          this.$message.error('上传失败')
          this.uploadItemDataList.filePath = ''
        }
      },
      // oss token
      async uploadFile() {
        const res = await uploadFile()
        if (res.code === 0) {
          this.ossToken = res.data
        } else {
          this.$message.error(res.msg)
        }
      },
      httpRequest() {

      },
      // 查看错误条数
      errorLook(list) {
        this.dialogVisible = true
        if (list === null) {
          this.newArry = []
          this.total = 0
        } else {
          this.tableDataT = list
          this.currentPage = 1
          this.getResultsData()
        }
      },
      // 前端自己分页
      getResultsData() {
        // this指向改一下
        var that = this
        var list = that.tableDataT // 后端回来表格的数据
        // 渲染的数据newArry赋值
        this.newArry = list.filter(
          (item, index) =>
          index < that.currentPage * that.pageSize &&
          index >= that.pageSize * (that.currentPage - 1)
        ) // 根据页数显示相应的内容
        this.total = list.length
      },
      // 分页
      handleCurrentChange(page) {
        this.currentPage = page
        this.getResultsData()
      },
      handleSizeChange(size) {
        this.pageSize = size
        this.getResultsData()
      }

    }
  }
</script>
<style lang="less" scoped>
  .view-upload-the-item {
    padding: 0 40px;

    /deep/ .el-dialog__wrapper {
      z-index: 200 !important;
    }

    .dialog-footer {
      .dialog-footer-1 {
        width: 180px;
        height: 44px;
        background: #fff;
        border-radius: 8px;
        border: 1px solid #cda156;
        text-align: center;
        line-height: 44px;
        color: #cda156;
        font-size: 18px;
      }

      .dialog-footer-2 {
        width: 180px;
        height: 44px;
        background: #f5edde;
        border-radius: 8px;
        border: none;
        text-align: center;
        line-height: 44px;
        color: #6e5121;
        font-size: 18px;
        margin-left: 20px;
      }
    }

    .centerTds {
      display: flex;
      flex-direction: column;
      align-items: center;

      .wx-tx {
        color: #cda156;
        font-size: 24px;
        padding: 10px 0;
      }

      .wx-wz {
        font-size: 16px;
        font-weight: 400;
        color: #666;
      }

      img {
        width: 100px;
      }
    }

    .top-upload {
      .item-title {
        text-align: left;
        font-size: 20px;
        font-weight: 600;
        color: #333;
        margin-bottom: 10px;
      }

      .upload-item {
        background: #faf4e5;
        padding: 34px 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .upload-item-left {
          display: flex;
          align-items: center;
          justify-content: center;

          .upload-item-left-1 {
            width: 100px;

            img {
              width: 100%;
            }
          }

          .upload-item-left-2 {
            margin-left: 30px;
            font-size: 16px;

            p {
              margin-bottom: 10px;
              color: #333;
            }

            a {
              text-decoration: underline;
              color: #cda156;
            }
          }
        }
      }

      /deep/ .el-upload-list {
        display: none;
      }

      /deep/ .el-button--primary {
        width: 140px;
        border-radius: 8px;
        background: #cda156;
        height: 44px;
        text-align: center;
        border: none;
        color: #fff;
        border-radius: 8px;
        display: block;
        font-size: 16px;
        opacity: 0.7;
      }

      .go-next {
        width: 280px;
        height: 44px;
        background: #cda156;
        border-radius: 8px;
        color: #6e5121;
        line-height: 44px;
        text-align: center;
        font-size: 18px;
        opacity: 0.7;
        display: block;
        margin: 20px auto 0 auto;
      }
    }

    /deep/ .el-table th.el-table__cell {
      background: #f5edde;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #cda156;
      color: #fff;
    }

    .el-pag-ination {
      margin-top: 20px;
      text-align: right;
      padding-right: 40px;
    }

    .middle-table {
      // padding: 0 20px;
      margin-top: 20px;

      /deep/ .el-table th.el-table__cell {
        background: rgba(245, 234, 204, 0.5);
      }

      /deep/ .el-table__header-wrapper {
        border-radius: 29px 29px 0px 0px !important;
      }

      /deep/ .el-table thead {
        color: #333333;
        font-size: 16px;
      }

      .red {
        color: #cda156;
        font-size: 16px;
        text-decoration: underline;
      }

      /deep/ .el-table {
        border-radius: 29px 29px 0 0;
      }
    }
  }
</style>
