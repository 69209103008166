<template>
  <div class="view-create-success">
    <div class="success-img">
      <img src="../../../assets/images/huaban.png" alt="">
      <span>您已成功创建拍卖会！</span>
    </div>
    <div class="success-but">
      <router-link target="_blank" class="success-look" :to="{path:'/previewlot', query:{eventsId:eventsId}}">
        预览
      </router-link>
      <!-- <button  @click="successLook()">预览</button> -->
      <button class="success-t-s" @click="success">提交审核</button>
    </div>
  </div>
</template>
<script>
import { auctionAuditStatus } from '../../../api/index'
export default {
  name: 'CreateSuccess',
  data () {
    return {
      auctionAuditStatusData: {
        auctionId: Number(localStorage.getItem('auctionId')),
        status: 4,
        authorization: localStorage.getItem('accessToken'),
        x_user_id: Number(localStorage.getItem('accessId'))
      },
      eventsId: 0
    }
  },
  props: {
    auctionIdLook: {
      type: Number
    }
  },
  created () {
    this.eventsId = localStorage.getItem('auctionId')
  },
  methods: {
    // 提交审核
    async success () {
      const res = await auctionAuditStatus(this.auctionAuditStatusData)
      if (res.code === 0) {
        this.$message.success('提交成功')
      } else {
        this.$message.error(res.msg)
      }
    },
    // 预览
    successLook () {
      this.$router.push({
        path: '/previewlot',
        name: 'Previewlot',
        query: {
          // eventsId: localStorage.getItem('')

        }
      })
    }
  }
}
</script>
<style lang="less" scoped>
.view-create-success {
  .success-img {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      font-size: 16px;
      font-weight: 400;
      color: #777;
      padding-top: 20px;
    }
  }
  .success-but {
    margin-top: 60px;
    .success-look {
      width: 280px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      color: #dcbd88;
      font-size: 18px;
      border: 1px solid #cda156;
      border-radius: 8px;
      background: #ffffff;
      display: inline-block;
    }
    .success-t-s {
      width: 280px;
      height: 44px;
      line-height: 44px;
      text-align: center;
      color: #6e5121;
      font-size: 18px;
      border: 1px solid #cda156;
      border-radius: 8px;
      background: #cda156;
      margin-left: 60px;
    }
  }
}
</style>
