<template>
  <div class="view-price-information">
    <bidding-ladder v-model="biddingLadderData" />
    <div class="submit-save">
      <button class="go-one" @click="submit()">保存</button>
    </div>
  </div>
</template>
<script>
import {
  setAuctionHousePriceInformation,
  getAuctionHouseRegisterDesc
} from '../../../../api/index'
import BiddingLadder from '@/components/Biddingladder.vue'
export default {
  name: 'PriceInformation',
  components: {
    BiddingLadder
  },
  data () {
    return {
      rules: [],
      biddingLadderData: {
        iconPriceList: 1,
        iconList: 1,
        buyersPremium: {
          min: [],
          max: []
        },
        bidding: {
          min: [],
          max: []
        }
      },
      setAuctionHousePriceInformation: {
        id: Number(localStorage.getItem('accessId')),
        x_user_id: Number(localStorage.getItem('accessId')) || 0,
        authorization: localStorage.getItem('accessToken')
      }
    }
  },
  created () {
    this.getAuctionHouseRegisterDesc()
  },
  methods: {
    async getAuctionHouseRegisterDesc () {
      const res = await getAuctionHouseRegisterDesc({
        userId: Number(localStorage.getItem('accessId')),
        x_user_id: Number(localStorage.getItem('accessId')),
        authorization: localStorage.getItem('accessToken')
      })

      if (res.code === 0) {
        this.biddingLadderData.buyersPremium.min = []
        this.biddingLadderData.buyersPremium.max = []
        this.biddingLadderData.bidding.min = []
        this.biddingLadderData.bidding.max = []
        // 佣金设置
        for (const key in res.data.buyersPremium.min) {
          // if (
          //   res.data.buyersPremium.min[key] === 99999999 ||
          //     res.data.buyersPremium.min[key] === 9999999999
          // ) {
          //   res.data.buyersPremium.min[key] = '∞'
          // }
          const lastIndex = Object.keys(res.data.buyersPremium.min).length - 1
          res.data.buyersPremium.min[key] = Number(key) === lastIndex ? '∞' : res.data.buyersPremium.min[key]
          const len = this.biddingLadderData.buyersPremium.min.length
          // const burMin = {
          //   index: len + 1,
          //   content: res.data.buyersPremium.min[key] === 999999999 ||
          //     res.data.buyersPremium.min[key] === 99999999 ?
          //     '∞' : this.format(res.data.buyersPremium.min[key])
          // }
          const burMin = {
            index: len + 1,
            content: Number(key) === lastIndex
              ? '∞' : this.format(res.data.buyersPremium.min[key])
          }
          this.biddingLadderData.buyersPremium.min.push(burMin)
        }
        for (const key in res.data.buyersPremium.max) {
          const len = this.biddingLadderData.buyersPremium.max.length
          const burMax = {
            index: len + 1,
            content: res.data.buyersPremium.max[key] === 0
              ? null : this.format(res.data.buyersPremium.max[key]),
            checked: false
          }
          this.biddingLadderData.buyersPremium.max.push(burMax)
        }
        // vat
        for (const key in res.data.buyersPremium.vat) {
          this.biddingLadderData.buyersPremium.max[key].checked =
              res.data.buyersPremium.vat[key]
        }
        for (const key in res.data.bidding.min) {
          // if (
          //   res.data.bidding.min[key] === 99999999 ||
          //     res.data.bidding.min[key] === 999999999 ||
          //     res.data.bidding.min[key] === 9999999999
          // ) {
          //   res.data.bidding.min[key] = '∞'
          // }
          const lastIndex = Object.keys(res.data.bidding.min).length - 1
          res.data.bidding.min[key] = Number(key) === lastIndex ? '∞' : res.data.bidding.min[key]
          const len = this.biddingLadderData.bidding.min.length
          const bidMin = {
            index: len + 1,
            content: this.format(res.data.bidding.min[key])
          }
          this.biddingLadderData.bidding.min.push(bidMin)
        }
        // 竞价阶梯右边
        let index = -1
        const maxArr = []
        for (const key in res.data.bidding.max) {
          this.biddingLadderData.bidding.max.push({
            index: (index += 1),
            data: []
          })
          maxArr.push(res.data.bidding.max[key])
        }
        for (let i = 0; i < maxArr.length; i++) {
          maxArr[i].forEach(item => {
            this.biddingLadderData.bidding.max[i].data.push({
              content: this.format(item),
              iconShow: true
            })
          })
          for (let j = 0; j < this.biddingLadderData.bidding.max.length; j++) {
            for (
              let index = 0; index < this.biddingLadderData.bidding.max[j].data.length; index++
            ) {
              const len = this.biddingLadderData.bidding.max[j].data.length - 1
              if (
                this.biddingLadderData.bidding.max[j].data[len].iconShow ===
                  true
              ) {
                this.biddingLadderData.bidding.max[j].data[len].iconShow = false
              }
            }
          }
        }
        this.biddingLadderData.iconPriceList = this.biddingLadderData.buyersPremium.max.length
        this.biddingLadderData.iconList = this.biddingLadderData.bidding.max.length
      } else {
        this.$message.error(res.msg)
      }
    },
    formatDel (num) {
      // 去除千分号
      const newval = num.toString().replace(/,/gi, '')
      return newval === '∞' ? '∞' : Number(newval)
    },
    format (num) {
      // 千分位
      return (num + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    },
    // 保存
    async submit () {
      let commissionReg = true
      this.biddingLadderData.buyersPremium.min.slice(1).map(item => {
        item.content = this.formatDel(item.content)
        if (
          item.content === 0 ||
            item.content === '' ||
            item.content === null
        ) {
          commissionReg = false
        }
      })
      this.biddingLadderData.buyersPremium.max.map(item => {
        item.content = this.formatDel(item.content)
        if (item.content === '' || item.content === null) {
          commissionReg = false
        }
      })
      if (!commissionReg) {
        return this.$message.error('佣金设置不能为空且左侧区间不能为0')
      }

      let biddingReg = true
      this.biddingLadderData.bidding.min.slice(1).map(item => {
        item.content = this.formatDel(item.content)
        if (!item.content) {
          biddingReg = false
        }
      })
      this.biddingLadderData.bidding.max.map(item => {
        item.data.map(item2 => {
          item2.content = this.formatDel(item2.content)
          if (!item2.content) {
            biddingReg = false
          }
        })
      })
      if (!biddingReg) {
        return this.$message.error('竞价阶梯不能为空且大于0')
      }
      // for (
      //   let index = 0;
      //   index < this.biddingLadderData.buyersPremium.min.length;
      //   index++
      // ) {
      //   if (
      //     this.biddingLadderData.buyersPremium.min[index].content === '' ||
      //     this.biddingLadderData.buyersPremium.min[index].content === '0'
      //   ) {
      //     this.$message.error('佣金设置不能为空或者不能为0')
      //     return
      //   }
      // }
      // for (
      //   let index = 0;
      //   index < this.biddingLadderData.buyersPremium.max.length;
      //   index++
      // ) {
      //   if (
      //     this.biddingLadderData.buyersPremium.max[index].content === '' ||
      //     this.biddingLadderData.buyersPremium.max[index].content === null ||
      //     this.biddingLadderData.buyersPremium.max[index].content === '0'
      //   ) {
      //     this.$message.error('佣金设置不能为空或者不能为0')
      //     return
      //   }
      // }
      // for (
      //   let index = 0;
      //   index < this.biddingLadderData.bidding.min.length;
      //   index++
      // ) {
      //   if (
      //     this.biddingLadderData.bidding.min[index].content === '' ||
      //     this.biddingLadderData.bidding.min[index].content === '0'
      //   ) {
      //     this.$message.error('竞价阶梯不能为空或者不能为0')
      //     return
      //   }
      // }
      // for (
      //   let index = 0;
      //   index < this.biddingLadderData.bidding.max.length;
      //   index++
      // ) {
      //   // if(this.biddingLadderData.bidding.max[index].data.length==2){
      //   //   return this.$message.error('竞价阶梯只能输入一个框或三个框')
      //   // }
      //   for (
      //     let j = 0;
      //     j < this.biddingLadderData.bidding.max[index].data.length;
      //     j++
      //   ) {
      //     if (
      //       this.biddingLadderData.bidding.max[index].data[j].content === '' ||
      //       this.biddingLadderData.bidding.max[index].data[j].content === '0' ||
      //       this.biddingLadderData.bidding.max[index].data[j].content === null
      //     ) {
      //       this.$message.error('竞价阶梯不能为空或者不能为0')
      //       return
      //     }
      //   }
      // }
      const params = Object.assign(
        this.setAuctionHousePriceInformation,
        this.biddingLadderData
      )
      const res = await setAuctionHousePriceInformation(params)
      if (res.resultCode === 0) {
        this.$message.success('保存成功')
        this.getAuctionHouseRegisterDesc()
      } else {
        this.$message.error(res.resultMsg)
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .view-price-information {
    background: #fff;
    padding: 30px 250px 60px 250px;
    width: 100%;
    margin-bottom: 38px;

    /deep/ .el-form-item__label {
      font-weight: 600;
      color: rgb(56, 55, 55);
      font-size: 16px;
    }

    .submit-save {
      .go-one {
        width: 280px;
        color: #6e5121;
        font-size: 18px;
        opacity: 0.7;
        border-radius: 8px;
        background: #cda156;
        height: 44px;
        text-align: center;
        line-height: 44px;
        border: none;
        float: left;
        margin-left: 230px;
      }
    }

    .pointer {
      cursor: pointer;
    }
  }
</style>
