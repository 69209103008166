<template>
  <div class="view-auction-list">
    <div class="header-list">
      <span class="list-left">拍卖会列表信息</span>
      <div class="all-b">
        <span>筛选</span>
        <el-select v-model="value" placeholder="请选择" @change="changeSelect">
          <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="middle-table">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="start_time" label="拍卖日期">
          <template slot-scope="scope">
            <span>{{scope.row.timeZoneDate}}({{scope.row.timeZone}})</span>
          </template>
        </el-table-column>
        <el-table-column prop="chinese_title_auction" label="场次名称">
        </el-table-column>
        <el-table-column prop="numberItems" label="拍品数量">
        </el-table-column>
        <el-table-column prop="turnover" label="成交金额">
        </el-table-column>
        <el-table-column label="拍卖状态">
          <template slot-scope="scope">
            <span>{{scope.row.auctionIsverify===1?'审核未通过':scope.row.auctionIsverify===2?'未申请审核':scope.row.auctionIsverify===3?'已申请审核':scope.row.auctionIsverify===4?'预约出价中':scope.row.auctionIsverify===5?'正在拍卖':scope.row.auctionIsverify===6?'拍卖完成':''}}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="success">
              <a href="javascript:;" type="text" v-if="scope.row.auctionIsverify === 2" @click="success(scope.row.id)"
                class="success-tt">
                提交审核
              </a>
              <a href="javascript:;" type="text" v-if="scope.row.auctionIsverify === 1" @click="success(scope.row.id)"
                class="success-tt">
                重新发起审核
              </a>
              <router-link target="_blank" class="success-tt" style="color:#cda156;text-decoration:none;"
                :to="{path:'/previewlot',query:{eventsId:scope.row.id}}" @click.native="goPreview(scope.row.id)">
                进入场次
              </router-link>
              <router-link target="_blank" @click.native="locaL" class="success-tt" v-if="scope.row.isShow === true"
                :to="{path:'/liveauctionhouse',query:{liveId:scope.row.id,firstItemId:scope.row.firstItemId,title:scope.row.chinese_title_auction}}">
                进入直播控制台
              </router-link>
            </div>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="el-pag-ination">
      <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange"
        :page-size="liveAuctionListData.pageSize" :current-page="liveAuctionListData.page">
      </el-pagination>
    </div>
  </div>
</template>
<script>
  import {
    liveAuctionList,
    auctionAuditStatus
  } from '../../../api/index'
  export default {
    name: 'AuctionList',
    data() {
      return {
        options: [{
            value: 0,
            label: '全部'
          }, {
            value: 1,
            label: '审核未通过'
          },
          {
            value: 2,
            label: '未申请审核'
          },
          {
            value: 3,
            label: '已申请审核'
          }, {
            value: 4,
            label: '预约出价中'
          }, {
            value: 5,
            label: '正在拍卖'
          }, {
            value: 6,
            label: '拍卖完成'
          }
        ],
        tableData: [],
        liveAuctionListData: {
          auctionIsverify: 0,
          page: 1,
          pageSize: 10,
          userId: Number(localStorage.getItem('accessId')),
          authorization: localStorage.getItem('accessToken'),
          x_user_id: Number(localStorage.getItem('accessId')),
          sort: ''
        },
        value: 0,
        total: 0,
        auctionAuditStatusData: {
          auctionId: 0,
          status: 4,
          authorization: localStorage.getItem('accessToken'),
          x_user_id: Number(localStorage.getItem('accessId'))
        }

      }
    },
    created() {
      this.liveAuctionList()
    },
    methods: {
      async liveAuctionList() {
        const res = await liveAuctionList(this.liveAuctionListData)
        if (res.code === 0) {
          this.tableData = res.data.list
          this.total = res.data.count
        } else {
          this.$message.error(res.msg)
        }
      },
      // 筛选
      changeSelect(event) {
        this.liveAuctionListData.auctionIsverify = event
        this.liveAuctionListData.page = 1
        this.liveAuctionList()
      },
      handleCurrentChange(page) {
        this.liveAuctionListData.page = page
        this.liveAuctionList()
      },
      // 进入场次
      events(id) {
        this.$router.push({
          path: '/previewlot',
          name: 'Previewlot',
          query: {
            eventsId: id
          }
        })
      },
      locaL() {
        localStorage.setItem('sige', 1)
      },
      // 提交审核
      async success(id) {
        this.auctionAuditStatusData.auctionId = id
        const res = await auctionAuditStatus(this.auctionAuditStatusData)
        if (res.code === 0) {
          this.$message.success('提交成功')
          this.liveAuctionList()
        } else {
          this.$message.error(res.msg)
        }
      },
      goPreview(id) {
        localStorage.setItem('auctionId', id)
      }
    }
  }
</script>
<style lang="less" scoped>
  .view-auction-list {
    background: #fff;
    margin-bottom: 60px;
    padding-bottom: 20px;

    .header-list {
      display: flex;
      justify-content: space-between;
      padding: 26px 40px;
      align-items: center;

      .list-left {
        font-size: 20px;
        font-weight: 600;
        color: #333;
      }

      .all-b {
        span {
          font-size: 18px;
          color: #333;
          font-weight: 400;
          padding-right: 20px;
        }

        /deep/ .el-input__inner {
          width: 120px;
        }
      }
    }

    .middle-table {
      padding: 0 40px;

      .success {
        display: flex;
        flex-direction: column;
        justify-content: left;

        /deep/ .el-button--text {
          text-align: left;
        }

        /deep/ .el-button+.el-button {
          margin-left: 0;
        }

        .success-tt {
          color: #d10000;
          font-size: 16px;
          text-decoration: underline;
        }
      }

      /deep/ .el-table th.el-table__cell {
        background: rgba(245, 234, 204, 0.5);
      }

      /deep/ .el-table__header-wrapper {
        border-radius: 29px 29px 0px 0px !important;
      }

      /deep/ .el-table thead {
        color: #333333;
        font-size: 16px;
      }
    }

    /deep/ .el-button--text {
      color: #cda156;
      font-size: 16px;
    }

    /deep/ .el-pagination.is-background .el-pager li:not(.disabled).active {
      background-color: #cda156;
      color: #fff;
    }

    .el-pag-ination {
      margin-top: 20px;
      text-align: right;
      padding-right: 40px;
    }
  }
</style>
