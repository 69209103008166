<template>
  <div class="view-new-auction">
    <div class="new-auction-title">新建拍卖会</div>
    <div class="step-auction">
      <el-steps :active="active">
        <el-step title="填写拍卖会信息"></el-step>
        <el-step title="上传拍品信息"></el-step>
        <el-step title="上传拍品图片"></el-step>
        <el-step title="创建成功"></el-step>
      </el-steps>
    </div>
    <div class="new-auction-form-1" v-if="marking === 1">
      <el-form :model="addAuctionData" :rules="rules" ref="addAuctionData" label-width="170px" class="demo-ruleForm">
        <el-form-item label="拍卖会封面" prop="image">
          <div class="upload-img">
            <el-upload action="" list-type="picture-card" :on-preview="handlePictureCardPreview" multiple :limit="limit"
              :on-exceed="handleExceedTwo" :http-request="httpRequest" :on-remove="removeChange" :on-change="getFileOne"
              :file-list="fileListOne" :class="{ hide: uploadShow }">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="" />
            </el-dialog>
            <span>上传封面图片文件不能大于2M，格式可以上传jpg、png、jpeg</span>
          </div>
        </el-form-item>
        <el-form-item label="拍卖会名称" prop="title">
          <el-input v-model="addAuctionData.title" placeholder="请输入拍卖会名称">
          </el-input>
        </el-form-item>
        <el-form-item label="拍卖会类型" prop="auctionWayId">
          <el-select v-model="addAuctionData.auctionWayId" placeholder="请选择">
            <el-option v-for="item in auctionWayIdData" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="时区" prop="timeZone">
          <el-select v-model="addAuctionData.timeZone" placeholder="请选择">
            <el-option v-for="item in auctionTimeZoneListData" :key="item.id" :label="item.utc + item.nameCn"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开拍时间" prop="startTime" style="align-items:normal">
          <el-date-picker v-model="addAuctionData.startTime" type="datetime" placeholder="选择日期时间" @change="time">
          </el-date-picker>
          <div class="terms-middle">
            <div class="terms">
              <input type="checkbox" v-model="checked" checked />
              <p>按照上一场拍卖会结束时间顺延</p>
            </div>
            <div class="terms" style="padding-left:30px">
              <input type="checkbox" v-model="addAuctionData.deferment" checked />
              <p>延期待定</p>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="拍卖地址" prop="attrPlaceSourceId">
          <div class="el-dis">
            <div class="el-select-box">
              <el-select v-model="addAuctionData.attrPlaceSourceId" @change="changeOne" placeholder="请选择">
                <el-option v-for="item in auctionAreaListData" :key="item.code" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </div>
            <div class="el-select-box" v-if="selectShow">
              <el-select v-model="addAuctionData.countryTwo" @change="changeTwo" placeholder="请选择">
                <el-option v-for="item in auctionAreaListDataTwo" :key="item.code" :label="item.name"
                  :value="{ value: item.code, label: item.name }">
                </el-option>
              </el-select>
            </div>
            <div class="el-select-box" v-if="selectShow">
              <el-select v-model="addAuctionData.countryShtree" @change="changeShtree" placeholder="请选择">
                <el-option v-for="item in auctionAreaListSthreeData" :key="item.code" :label="item.name"
                  :value="{ value: item.code, label: item.name }">
                </el-option>
              </el-select>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="详细地址" prop="location">
          <el-input type="textarea" v-model="addAuctionData.location" placeholder="请填写您的详细地址"></el-input>
        </el-form-item>
        <el-form-item label="保证金比例" prop="earnestMoney">
          <el-input v-model="addAuctionData.earnestMoney" placeholder="请输入保证金比例,比如（1:2）">
          </el-input>
        </el-form-item>
        <el-form-item label="货币单位" prop="monetaryUnit">
          <el-select v-model="addAuctionData.monetaryUnit" @change="changeMonetaryUnit" placeholder="请选择">
            <el-option v-for="item in monetaryUnitData" :key="item.id" :label="item.currency" :value="item.currency">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类" prop="classifyId">
          <div class="classify-Id">
            <el-checkbox-group v-model="addAuctionData.classifyId">
              <el-checkbox v-for="item in valueAttribute" :label="item.id" :key="item.id">
                {{ item.name }}
              </el-checkbox>
            </el-checkbox-group>

            <!--  <el-checkbox v-model="addAuctionData.classifyId" v-for="item in valueAttribute" :label="item.id"
              :key="item.id">
              {{ item.name }}
            </el-checkbox> -->

          </div>
        </el-form-item>
        <el-form-item label="拍卖条款" prop="auctionTerm">
          <el-input type="textarea" v-model="addAuctionData.auctionTerm" placeholder="请填写拍卖条款"></el-input>
        </el-form-item>
        <el-form-item label="付款方式" prop="paymentMethod">
          <el-input type="textarea" v-model="addAuctionData.paymentMethod" placeholder="请填写付款方式"></el-input>
        </el-form-item>
        <el-form-item label="邮寄方式" prop="mailingMethod">
          <el-input type="textarea" v-model="addAuctionData.mailingMethod" placeholder="请填写邮寄方式"></el-input>
        </el-form-item>
        <bidding-ladder v-model="biddingLadderData" />

        <div class="button-okz">
          <button class="go-one" @click.prevent="nextUpload('addAuctionData')">
            下一步
          </button>
          <p>
            <input type="checkbox" v-model="checkedTwo" checked />
            <span>我已阅读并同意</span>
            <a href="javascript:;">《焱十一拍卖行协议》</a>
          </p>
        </div>
      </el-form>
    </div>
    <!-- 上传拍品信息 -->
    <upload-the-item v-if="marking === 2" @changeData="changeData" />
    <!-- 上传拍品图片 -->
    <upload-picture-item v-if="marking === 3" @picture="picture" />
    <!-- 创建成功 -->
    <create-success v-if="marking === 4" :auctionIdLook="auctionIdLook" />
  </div>
</template>
<script>
  import OSS from 'ali-oss'
  import {
    uploadFile,
    auctionTimeZoneList,
    auctionAreaList,
    exchangeRateList,
    addAuction,
    getAuctionHouseRegisterDesc,
    auctionAttribute
  } from '../../../api/index'
  import UploadTheItem from './Uploadtheitem.vue'
  import UploadPictureItem from './Uploadpictureitem.vue'
  import CreateSuccess from './Createsuccess.vue'
  import {
    checkSpecificKey
  } from '../../../utils/validator'
  import BiddingLadder from '@/components/Biddingladder.vue'
  export default {
    name: 'NewAuction',
    components: {
      UploadTheItem,
      UploadPictureItem,
      CreateSuccess,
      BiddingLadder
    },
    data() {
      var valiIcon = (rule, value, callback) => {
        // 图片验证
        if (!this.addAuctionData.image) {
          callback(new Error('请上传拍卖会封面'))
        } else {
          callback()
        }
      }
      var validateInput = (rule, value, callback) => {
        if (!checkSpecificKey(value)) {
          callback(new Error('请输入英文下的冒号'))
        } else {
          callback()
        }
      }
      return {
        active: 1,
        addAuctionData: {
          userId: Number(localStorage.getItem('accessId')),
          auctionId: 0,
          authorization: localStorage.getItem('accessToken'),
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          iconPriceList: 1,
          iconList: 1,
          image: '',
          monetaryUnit: '',
          title: '',
          timeZone: '',
          earnestMoney: '',
          auctionPostpone: '',
          startTime: '',
          attrPlaceSourceId: '',
          auctionTerm: '',
          location: '',
          paymentMethod: '',
          mailingMethod: '',
          areaName: '',
          deferment: false,
          auctionWayId: null,
          classifyId: []
        },
        limit: 1,
        marking: 1,
        dialogImageUrl: '',
        selectShow: true,
        ossToken: '',
        fileListOne: [],
        dialogVisible: false,
        rules: {
          image: [{
            required: true,
            validator: valiIcon,
            trigger: 'change'
          }],
          title: [{
            required: true,
            message: '请输入拍卖会名称',
            trigger: 'change'
          }],
          timeZone: [{
            required: true,
            message: '请选择时区',
            trigger: 'change'
          }],
          startTime: [{
            required: true,
            message: '请选择开拍时间',
            trigger: 'change'
          }],
          attrPlaceSourceId: [{
            required: true,
            message: '请选择拍卖地址',
            trigger: 'change'
          }],
          auctionWayId: [{
            required: true,
            message: '请选择拍卖会类型',
            trigger: 'change'
          }],
          classifyId: [{
              type: 'array',
              required: true,
              message: '请选择分类',
              trigger: 'change'
            }
            // {  trigger: 'change' }
          ],
          location: [{
            required: true,
            message: '请填写详细地址',
            trigger: 'change'
          }],
          earnestMoney: [
            // { required: true, trigger: 'change', message: '请输入保证金' },
            // { validator: validateInput, trigger: 'change' },
            {
              required: true,
              message: '请填写保证金比例，例如1:2',
              trigger: 'change'
            },
            {
              required: true,
              pattern: '^([\u4E00-\uFA29]|[\uE7C7-\uE7F3]|[0-9_:]){1,20}$',
              message: '只能输入数字和英文:',
              trigger: ['blur', 'change']
            }
          ],
          monetaryUnit: [{
            required: true,
            message: '请选择货币单位',
            trigger: 'change'
          }],
          auctionTerm: [{
            required: true,
            message: '请输入拍卖条款',
            trigger: 'change'
          }],
          paymentMethod: [{
            required: true,
            message: '请输入付款方式',
            trigger: 'change'
          }],
          mailingMethod: [{
            required: true,
            message: '请输入邮寄方式',
            trigger: 'change'
          }],
          buyersPremium: [{
            required: true,
            message: '请填写佣金设置',
            trigger: 'change'
          }],
          bidding: [{
            required: true,
            message: '请填写竞价阶梯',
            trigger: 'change'
          }]
        },
        auctionTimeZoneListData: [],
        checked: false,
        checkedTwo: true,
        auctionAreaListData: [],
        auctionAreaListDataTwo: [],
        auctionAreaListSthreeData: [],
        countyTxtTwo: '',
        countyTxtStree: '',
        monetaryUnitData: [],
        getAuctionHouseRegisterDescData: {},
        auctionIdLook: 0,
        uploadShow: false,
        valueAttribute: [],
        checkedId: [],
        auctionWayIdData: [{
            id: 7,
            name: '直播拍卖'
          },
          {
            id: 8,
            name: '委托拍卖'
          }
        ],
        biddingLadderData: {
          iconPriceList: 1,
          iconList: 1,
          buyersPremium: {
            min: [],
            max: []
          },
          bidding: {
            min: [],
            max: []
          }
        }
      }
    },
    created() {
      if (localStorage.getItem('reEstablishId') === '3') {
        this.marking = 2
        this.active = 2
      } else if (localStorage.getItem('reEstablishId') === '4') {
        this.marking = 3
        this.active = 3
      }
      this.auctionTimeZoneList()
      this.auctionAreaList()
      this.exchangeRateList()
      this.getAuctionHouseRegisterDesc()
      this.auctionAttribute()
    },
    methods: {
      // 回显数据
      async getAuctionHouseRegisterDesc() {
        const res = await getAuctionHouseRegisterDesc({
          authorization: localStorage.getItem('accessToken'),
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          userId: Number(localStorage.getItem('accessId'))
        })
        if (res.code === 0) {
          this.getAuctionHouseRegisterDescData = res.data
          this.addAuctionData.auctionTerm = this.getAuctionHouseRegisterDescData.terms
          this.addAuctionData.paymentMethod = this.getAuctionHouseRegisterDescData.paymentMethod
          this.addAuctionData.mailingMethod = this.getAuctionHouseRegisterDescData.mailingMethod
          // 佣金设置
          for (const key in res.data.buyersPremium.min) {
            // if (
            //   res.data.buyersPremium.min[key] === 99999999 ||
            //   res.data.buyersPremium.min[key] === 999999999 ||
            //   res.data.buyersPremium.min[key] === 9999999999
            // ) {
            //   res.data.buyersPremium.min[key] = '∞'
            // }
            const lastIndex = Object.keys(res.data.buyersPremium.min).length - 1
            res.data.buyersPremium.min[key] = Number(key) === lastIndex ? '∞' : res.data.buyersPremium.min[key]
            const len = this.biddingLadderData.buyersPremium.min.length
            // const burMin = {
            //   index: len + 1,
            //   content: res.data.buyersPremium.min[key] === 999999999 ||
            //       res.data.buyersPremium.min[key] === 99999999
            //     ? '∞'
            //     : this.format(res.data.buyersPremium.min[key])
            // }
            const burMin = {
              index: len + 1,
              content: Number(key) === lastIndex ? '∞' : this.format(res.data.buyersPremium.min[key])
            }
            this.biddingLadderData.buyersPremium.min.push(burMin)
          }
          for (const key in res.data.buyersPremium.max) {
            const len = this.biddingLadderData.buyersPremium.max.length
            const burMax = {
              index: len + 1,
              content: this.format(res.data.buyersPremium.max[key]),
              checked: false
            }
            this.biddingLadderData.buyersPremium.max.push(burMax)
          }
          // vat
          for (const key in res.data.buyersPremium.vat) {
            this.biddingLadderData.buyersPremium.max[key].checked =
              res.data.buyersPremium.vat[key]
          }
          // 竞价阶梯设置
          for (const key in res.data.bidding.min) {
            // if (
            //   res.data.bidding.min[key] === 99999999 ||
            //     res.data.bidding.min[key] === 999999999 ||
            //     res.data.bidding.min[key] === 9999999999
            // ) {
            //   res.data.bidding.min[key] = '∞'
            // }
            const lastIndex = Object.keys(res.data.bidding.min).length - 1
            res.data.bidding.min[key] = Number(key) === lastIndex ? '∞' : res.data.bidding.min[key]
            const len = this.biddingLadderData.bidding.min.length
            const bidMin = {
              index: len + 1,
              content: this.format(res.data.bidding.min[key])
            }
            this.biddingLadderData.bidding.min.push(bidMin)
          }
          // 竞价阶梯右边
          let index = -1
          const maxArr = []
          for (const key in res.data.bidding.max) {
            this.biddingLadderData.bidding.max.push({
              index: (index += 1),
              data: []
            })
            maxArr.push(res.data.bidding.max[key])
          }
          for (let i = 0; i < maxArr.length; i++) {
            maxArr[i].forEach(item => {
              this.biddingLadderData.bidding.max[i].data.push({
                content: this.format(item),
                iconShow: true
              })
            })
            for (let j = 0; j < this.biddingLadderData.bidding.max.length; j++) {
              for (
                let index = 0; index < this.biddingLadderData.bidding.max[j].data.length; index++
              ) {
                const len = this.biddingLadderData.bidding.max[j].data.length - 1
                if (
                  this.biddingLadderData.bidding.max[j].data[len].iconShow ===
                  true
                ) {
                  this.biddingLadderData.bidding.max[j].data[len].iconShow = false
                }
              }
            }
          }
          this.biddingLadderData.iconPriceList = this.biddingLadderData.buyersPremium.max.length
          this.biddingLadderData.iconList = this.biddingLadderData.bidding.max.length
        } else {
          this.$message.error(res.msg)
        }
      },
      // 获取时区
      async auctionTimeZoneList() {
        const res = await auctionTimeZoneList({
          authorization: localStorage.getItem('accessToken'),
          x_user_id: Number(localStorage.getItem('accessId')) || 0
        })
        if (res.code === 0) {
          this.auctionTimeZoneListData = res.data.list
        } else {
          this.$message.error(res.msg)
        }
      },
      // 获取单位
      async exchangeRateList() {
        const res = await exchangeRateList()
        if (res.code === 0) {
          this.monetaryUnitData = res.data.list
        } else {
          this.$message.error(res.msg)
        }
      },
      formatDel(num) {
        // 去除千分号
        const newval = num.toString().replace(/,/gi, '')
        return newval === '∞' ? '∞' : Number(newval)
      },
      format(num) {
        // 千分位
        return (num + '').replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
      },
      // 下一步
      async nextUpload(formName) {
        this.$refs[formName].validate(async valid => {
          if (valid) {
            if (this.checkedTwo === false) {
              this.$message.error('请勾选上方协议')
              return
            }

            // for (
            //   let index = 0;
            //   index < this.biddingLadderData.buyersPremium.min.length;
            //   index++
            // ) {
            //   if (
            //     this.biddingLadderData.buyersPremium.min[index].content === '' ||
            //     this.biddingLadderData.buyersPremium.min[index].content === '0'
            //   ) {
            //     this.$message.error('佣金设置不能为空或者不能为0')
            //     return
            //   }
            // }
            // for (
            //   let index = 0;
            //   index < this.biddingLadderData.buyersPremium.max.length;
            //   index++
            // ) {
            //   if (
            //     this.biddingLadderData.buyersPremium.max[index].content === '' ||
            //     this.biddingLadderData.buyersPremium.max[index].content ===
            //       null ||
            //     this.biddingLadderData.buyersPremium.max[index].content === 0
            //   ) {
            //     this.$message.error('佣金设置不能为空或者不能为0')
            //     return
            //   }
            // }
            // for (
            //   let index = 0;
            //   index < this.biddingLadderData.bidding.min.length;
            //   index++
            // ) {
            //   if (
            //     this.biddingLadderData.bidding.min[index].content === '' ||
            //     this.biddingLadderData.bidding.min[index].content === '0'
            //   ) {
            //     this.$message.error('竞价阶梯不能为空或者不能为0')
            //     return
            //   }
            // }
            // for (
            //   let index = 0;
            //   index < this.biddingLadderData.bidding.max.length;
            //   index++
            // ) {
            //   // if(this.biddingLadderData.bidding.max[index].data.length==2){
            //   //   return this.$message.error('竞价阶梯右侧区间只能输入一个框或者三个框')
            //   // }
            //   for (
            //     let j = 0;
            //     j < this.biddingLadderData.bidding.max[index].data.length;
            //     j++
            //   ) {
            //     if (
            //       this.biddingLadderData.bidding.max[index].data[j].content ===
            //         '' ||
            //       this.biddingLadderData.bidding.max[index].data[j].content ===
            //         '0' ||
            //       this.biddingLadderData.bidding.max[index].data[j].content ===
            //         null
            //     ) {
            //       this.$message.error('竞价阶梯不能为空或者不能为0')
            //       return
            //     }
            //   }
            // }
            let commissionReg = true
            this.biddingLadderData.buyersPremium.min.slice(1).map(item => {
              item.content = this.formatDel(item.content)
              if (
                item.content === 0 ||
                item.content === '' ||
                item.content === null
              ) {
                commissionReg = false
              }
            })
            this.biddingLadderData.buyersPremium.max.map(item => {
              item.content = this.formatDel(item.content)
              if (item.content === '' || item.content === null) {
                commissionReg = false
              }
            })
            if (!commissionReg) {
              return this.$message.error('佣金设置不能为空且左侧区间不能为0')
            }

            let biddingReg = true
            this.biddingLadderData.bidding.min.slice(1).map(item => {
              item.content = this.formatDel(item.content)
              if (!item.content) {
                biddingReg = false
              }
            })
            this.biddingLadderData.bidding.max.map(item => {
              item.data.map(item2 => {
                item2.content = this.formatDel(item2.content)
                if (!item2.content) {
                  biddingReg = false
                }
              })
            })
            if (!biddingReg) {
              return this.$message.error('竞价阶梯不能为空且大于0')
            }
            if (this.checked === true) {
              this.addAuctionData.auctionPostpone = 2
            } else {
              this.addAuctionData.auctionPostpone = 1
            }
            let copyAddAuctionData = JSON.parse(JSON.stringify(this.addAuctionData))
            copyAddAuctionData.classifyId = copyAddAuctionData.classifyId.toString()
            const params = Object.assign(
              copyAddAuctionData,
              this.biddingLadderData
            )
            //console.log(JSON.stringify(params))
            const res = await addAuction(params)
            if (res.code === 0) {
              localStorage.setItem('auctionId', res.data.AuctionId)
              this.auctionIdLook = res.data.AuctionId
              if (this.active++ > 4) this.active = 1
              this.marking = 2
            } else {
              this.$message.error(res.msg)
            }
          } else {
            return false
          }
        })
      },
      changeData(data) {
        if (this.active++ > 4) this.active = 1
        this.marking = data
      },
      picture(data) {
        if (this.active++ > 4) this.active = 1
        this.marking = data
      },
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url
        this.dialogVisible = true
      },
      httpRequest() {},
      handleExceedTwo(files, fileList) {
        this.$message.warning('当前限制上传 1 个文件')
      },
      // oss token
      async uploadFile() {
        const res = await uploadFile()
        if (res.code === 0) {
          this.ossToken = res.data
        } else {
          this.$message.error(res.msg)
        }
      },
      // 上传拍卖会封面
      async getFileOne(event) {
        const isLt300K = event.raw.size / 1024 / 1024 < 2
        const isTypeTrue = /^image\/(jpeg|png|jpg)$/.test(event.raw.type)
        if (!isLt300K) {
          this.$message.error('上传图片大小不能超过2M!')
          this.fileListOne = []
          return
        }
        if (!isTypeTrue) {
          this.$message.error('上传图片格式不对!')
          this.fileListOne = []
          return
        }
        var uploadHost = 'https://pic.easyebid.com'
        await this.uploadFile()
        var client = new OSS({
          region: 'oss-cn-beijing',
          accessKeyId: this.ossToken.accessKeyId,
          accessKeySecret: this.ossToken.accessKeySecret,
          bucket: 'yideonline',
          stsToken: this.ossToken.securityToken
        })
        var file = event.raw
        const type = file.name.split('.')
        var timestamp = Date.parse(new Date())
        const len = type.length
        const fileName = 'yidefm' + timestamp + '.' + type[len - 1]
        const result = await client.put(`avatar/${fileName}`, file)
        this.addAuctionData.image = `${uploadHost}/${result.name}`
        console.log(this.addAuctionData.image)
        this.$refs.addAuctionData.validateField('image')
        if (this.addAuctionData.image !== '') {
          this.uploadShow = true
        }
      },
      removeChange(file, fileList) {
        this.uploadShow = false
        this.addAuctionData.image = ''
        this.$refs.addAuctionData.validateField('image')
      },
      // 获取所属国家
      async auctionAreaList() {
        const res = await auctionAreaList({
          code: ''
        })
        if (res.code === 0) {
          this.auctionAreaListData = res.data.newList
        } else {
          this.$message.error(res.msg)
        }
      },
      async auctionAreaListTwo(id) {
        if (id === 0) {
          id = '000'
        }
        const res = await auctionAreaList({
          code: id
        })
        if (res.code === 0) {
          this.auctionAreaListDataTwo = res.data.list
        } else {
          this.$message.error(res.msg)
        }
      },
      async auctionAreaListSthree(id) {
        const res = await auctionAreaList({
          code: id.toString()
        })
        if (res.code === 0) {
          this.auctionAreaListSthreeData = res.data.list
        } else {
          this.$message.error(res.msg)
        }
      },
      // 选择国家
      changeOne(params) {
        const {
          value,
          label
        } = params
        this.countyTxtCode = value
        if (params !== 0) {
          this.selectShow = false
        } else {
          this.selectShow = true
          this.auctionAreaListTwo(params)
          this.countyTxt = label
        }
      },
      changeTwo(params) {
        const {
          value,
          label
        } = params
        if (this.selectShow === true) {
          this.auctionAreaListSthree(value)
          this.countyTxtTwo = label
        }
      },
      changeShtree(params) {
        const {
          value,
          label
        } = params
        this.countyTxtStree = label
        this.addAuctionData.areaName =
          this.countyTxtTwo + ',' + this.countyTxtStree
      },
      // 选择货币单位
      changeMonetaryUnit() {},
      // 开拍时间
      time(date) {
        const Y = date.getFullYear()
        const M =
          date.getMonth() + 1 - 0 >= 10 ?
          Number(date.getMonth()) + 1 :
          '0' + (Number(date.getMonth()) + 1)
        const D = date.getDate() >= 10 ? date.getDate() : '0' + date.getDate()
        const h = date.getHours() >= 10 ? date.getHours() : '0' + date.getHours()
        const m =
          date.getMinutes() >= 10 ? date.getMinutes() : '0' + date.getMinutes()
        const s =
          date.getSeconds() >= 10 ? date.getSeconds() : '0' + date.getSeconds()
        this.addAuctionData.startTime =
          Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
      },
      async auctionAttribute() {
        const res = await auctionAttribute({
          id: 0,
          userid: Number(localStorage.getItem('accessId')) || 0,
          x_user_id: Number(localStorage.getItem('accessId')) || 0,
          authorization: localStorage.getItem('accessToken') || 0,
          auctionWayId: '',
          brandAuthorId: '',
          stylePeriodId: '',
          materialsCraftId: '',
          placeSourceId: '',
          auctionHouseId: '',
          time: '',
          page: 1,
          pageSize: 40,
          title: '',
          classifyId: '',
          twoClassifyId: '',
          auctionType: 0
        })
        if (res.code === 0) {
          this.valueAttribute = res.data.list[0].valueAttribute
        } else {
          this.$message.error(res.msg)
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .view-new-auction {
    background: #fff;
    padding-top: 30px;
    margin-bottom: 60px;
    padding-bottom: 40px;

    /deep/ .hide .el-upload--picture-card {
      display: none;
    }

    /deep/ .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #666666;
    }

    /deep/ .el-checkbox__label {
      color: #666666;
    }

    /deep/ .el-checkbox__input.is-checked .el-checkbox__inner,
    .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #cda156;
      border-color: #cda156;
    }

    .step-auction {
      // width: 408px;
      // margin: 0 auto;
      padding: 0 400px;
      padding-bottom: 20px;

      /deep/ .el-step.is-horizontal {
        flex-basis: 0% !important;
      }

      /deep/ .el-step__line-inner {
        color: #cda156;
      }

      /deep/ .el-step__head.is-finish {
        color: #cda156;
        border-color: #cda156;
      }

      // /deep/ .el-step__icon-inner {
      //   color: #cda156;
      // }
      // /deep/ .el-step__icon-inner {
      //   color: #cda156;
      // }
      /deep/ .el-step__icon.is-text {
        margin-right: 274px !important;
      }

      /deep/ .el-step__title.is-finish {
        color: #cda156;
      }

      /deep/ .el-step__main {
        width: 120px;
        margin-left: -40px;
      }
    }

    .new-auction-title {
      color: #333;
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 40px;
    }

    .new-auction-form-1 {
      padding-left: 500px;

      /deep/ .el-upload-list--picture-card .el-upload-list__item {
        width: 120px;
        height: 80px;
      }

      .upload-img {
        display: flex;
        align-items: center;

        span {
          padding-left: 20px;
        }

        /deep/ .el-upload--picture-card {
          width: 120px;
          height: 80px;
          background: #fff;
          border: 1px solid #bfc3c4;
          line-height: 80px;
        }

        /deep/ .el-icon-plus {
          background: url('../../../assets/images/shangchuantupian备份 2@2x.png') no-repeat;
          background-size: 27px;
        }

        /deep/ .el-upload--picture-card i {
          color: transparent;
        }
      }

      /deep/ .el-form-item {
        display: flex;
        align-items: center;
        // width: 730px;
      }

      /deep/ .el-form-item__content {
        margin-left: 0 !important;
      }

      /deep/ .el-form-item__label {
        font-weight: 600;
        color: rgb(56, 55, 55);
        font-size: 16px;
      }

      /deep/ .el-date-editor.el-input,
      .el-date-editor.el-input__inner {
        width: 100%;
      }

      /deep/ .el-input__inner {
        width: 600px;
      }

      .el-dis {
        display: flex;
        justify-content: space-between;
        width: 600px;

        .el-select-box {
          width: 195px;

          /deep/ .el-input__inner {
            width: 195px;
          }
        }
      }

      /deep/ .el-textarea__inner {
        width: 600px;
        height: 100px;
        resize: none;
        outline: none;
        border: 1px solid #bfc3c4;
        padding-left: 13px;
      }

      .textarea-box {
        width: 600px;

        .textarea {
          width: 100%;
          height: 100px;
          resize: none;
          outline: none;
          border: 1px solid #bfc3c4;
          padding-left: 13px;
        }
      }

      .terms-middle {
        display: flex;
      }

      .terms {
        text-align: left;
        display: flex;
        align-items: center;
        // input[type="checkbox"] {
        //   cursor: pointer;
        //   position: relative;
        //   width: 15px;
        //   height: 15px;
        //   font-size: 14px;
        // }

        // input[type="checkbox"]::after {
        //   position: absolute;
        //   top: 0;
        //   color: #000;
        //   width: 15px;
        //   height: 15px;
        //   display: inline-block;
        //   visibility: visible;
        //   padding-left: 0px;
        //   text-align: center;
        //   content: " ";
        //   border-radius: 3px;
        // }

        // input[type="checkbox"]:checked::after {
        //   content: "✓";
        //   color: #fff;
        //   font-size: 12px;
        //   font-weight: bold;
        //   background-color: #cda156;
        // }
        a {
          color: #cda156;
        }

        p {
          font-weight: 400;
          color: #666;
          font-size: 12px;
          margin-bottom: 0;
          margin-left: 6px;
        }
      }

      .rightc {
        width: 800px;
        overflow: hidden;
        line-height: 41px;

        .pricerange2 {
          width: 800px;
          clear: both;
          overflow: hidden;
          text-align: left;

          .leftcp {
            width: 124px;
            float: left;
            overflow: hidden;

            h2 {
              font-size: 16px;
              line-height: 20px;
              text-align: center;
              padding: 5px 0;
              background: #f2f2f2;
              color: #999;
            }

            .cell {
              margin-top: 20px;
              text-align: center;
              height: 40px;

              .texts {
                height: 38px;
                width: 122px;
                border: 1px solid #ccc;
                background: #fff;
                text-align: center;
                font: normal 16px/38px Gotham, 'Helvetica Neue', Helvetica, Arial,
                  sans-serif;
                margin: 0;
                padding: 0;
                border-radius: 5px;
              }

              .infinite {
                background: url('../../../assets/images/icon_wx.png') no-repeat center center;
              }
            }

            .oper {
              clear: both;
              overflow: hidden;

              a {
                text-decoration: none;
                background-color: transparent;
                color: #666;
                font-family: Arial, Helvetica, '微软雅黑', 'Microsoft YaHei';
              }
            }
          }

          .rightcp {
            width: max-content;
            float: left;

            h2 {
              font-size: 16px;
              line-height: 20px;
              padding: 5px 0 5px 75px;
              text-align: left;
              margin-bottom: 60px;
              background: #f2f2f2;
              color: #999;
              width: 480px;
            }

            // .cell-2 {
            //     margin-bottom: 20px !important;
            // }
            .cell {
              text-align: right;
              margin-bottom: 20px;
              overflow: hidden;
              padding-left: 80px;
              clear: both;
              // width: 596px;
              height: 40px;
              background: url('../../../assets/images/arrow.png') no-repeat 20px top;
              display: flex;
              align-items: center;

              .texts {
                height: 38px;
                width: 122px;
                border: 1px solid #ccc;
                background: #fff;
                text-align: center;
                font: normal 16px/38px Gotham, 'Helvetica Neue', Helvetica, Arial,
                  sans-serif;
                margin: 0;
                padding: 0;
                border-radius: 5px;
                float: left;
              }

              span {
                float: left;
                text-align: left;
                height: 38px;
                line-height: 38px;
                margin-left: 8px;
              }

              .add {
                width: 21px;
                height: 21px;
                background: url('../../../assets/images/icon_add.png') no-repeat;
                overflow: hidden;
                float: left;
                margin: 0 0 0 10px;
              }

              .ad-ip {
                display: inline-block;

                .line {
                  width: 20px;
                  text-align: center;
                  color: #ccc;
                  line-height: 34px;
                  float: left;
                }
              }

              .reduce {
                width: 21px;
                height: 21px;
                background: url('../../../assets/images/icon_-.png');
                overflow: hidden;
                // float: left;
                margin: 10px;
                display: inline-block;
              }
            }
          }
        }
      }
    }

    .classify-Id {
      width: 450px;

      // display: flex;
      // justify-content: space-between;
      // flex-flow: wrap;
      /deep/.el-checkbox-group {
        text-align: left;
        display: flex;
        justify-content: space-between;
        flex-flow: wrap;
      }
    }

    .button-okz {
      padding-left: 300px;
      display: flex;
      flex-direction: column;

      .go-one {
        width: 280px;
        color: #6e5121;
        font-size: 18px;
        opacity: 0.7;
        border-radius: 8px;
        background: #cda156;
        height: 44px;
        // text-align: center;
        line-height: 44px;
        border: none;
        margin-top: 40px;
        margin-bottom: 10px;
      }

      p {
        margin-bottom: 0;
        font-size: 12px;
        display: flex;
        align-items: center;
        padding-left: 28px;

        span {
          padding-left: 5px;
        }

        a {
          color: #cda156;
        }
      }
    }
  }
</style>
