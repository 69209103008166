<template>
  <div class="view-auction-persona-center clearfix">
    <div class="view-banner">
      <a href="javascript:;" v-for="(item,index) in bannerList" :class="{active:num===index}" :key="index"
        class="view-banner-box" @click="toggle(item.id)">{{item.name}}</a>
    </div>
    <!-- 账户设置 -->
    <account-setup v-if="num === 2" />
    <!-- 拍卖会列表 -->
    <auction-list v-if="num===1" />
    <!-- 新建拍卖会 -->
    <new-auction v-if="num===0" />
  </div>
</template>
<script>
  import AccountSetup from './Accountsetup/Accountsetup.vue'
  import AuctionList from './Auctionlist/Auctionlist.vue'
  import NewAuction from './Newauction/Newauction.vue'
  export default {
    name: 'Auctionpersonalcenter',
    components: {
      AccountSetup,
      AuctionList,
      NewAuction
    },
    data() {
      return {
        bannerList: [{
            id: 0,
            name: '新建拍卖会'
          },
          {
            id: 1,
            name: '拍卖会列表'
          },
          {
            id: 2,
            name: '账户设置'
          }
        ],
        num: 2
      }
    },
    created() {
      if (localStorage.getItem('reEstablishId') === '3' || localStorage.getItem('reEstablishId') === '4') {
        this.num = 0
      }
    },
    methods: {
      toggle(id) {
        localStorage.removeItem('reEstablishId')
        this.num = id
        if (this.num === 0) {
          localStorage.removeItem('isNew')
        }
      }
    }
  }
</script>
<style lang="less" scoped>
  .view-auction-persona-center {
    padding: 0 90px;
    background: #f9f9f9;

    .view-banner {
      height: 70px;
      background: #fff;
      margin: 20px 0;
      display: flex;
      align-items: center;

      .view-banner-box {
        flex: 1;
        font-size: 20px;
        font-weight: 400;
        color: #333;
        border-right: 1px solid #dedede;

        &:hover {
          color: #cda156;
          text-decoration: underline;
        }
      }

      .view-banner-box:last-child {
        border-right: none;
      }

      .active {
        color: #cda156;
        font-weight: 600;
        text-decoration: underline;
      }
    }

    .clearfix:before,
    .clearfix:after {
      content: "";
      display: table;
    }

    .clearfix:after {
      clear: both;
    }

    .clearfix {
      *zoom: 1;
    }
  }
</style>
