<template>
  <div class="view-account-setup">
    <div class="view-account-setup-left">
      <a href="javascript:;" class="view-account-setup-a" :class="{active:num===index}" v-for="(item,index) in leftLift"
        :key="index" @click="toggle(item.id)">{{item.name}}</a>
    </div>
    <!-- 基本信息 -->
    <account-setup-info v-if="num===0" />
    <!-- 账单信息 -->
    <bill-setting v-if="num===1" />
    <!-- 价格信息 -->
    <price-information v-if="num===2" />
    <!-- 账号信息 -->
    <account-information v-if="num===3" />
  </div>
</template>
<script>
import AccountSetupInfo from './components/Accountsetupinfo'
import BillSetting from './components/Billsetting'
import PriceInformation from './components/Priceinformation'
import AccountInformation from './components/Accountinformation'
export default {
  name: 'Accountsetup',
  components: {
    AccountSetupInfo,
    BillSetting,
    PriceInformation,
    AccountInformation
  },
  data () {
    return {
      leftLift: [
        {
          id: 0,
          name: '基本信息'
        },
        {
          id: 1,
          name: '账单信息'
        },
        {
          id: 2,
          name: '价格信息'
        },
        {
          id: 3,
          name: '账号信息'
        },
        {
          id: 4,
          name: '退出登录'
        }

      ],
      num: 0
    }
  },
  methods: {
    toggle (id) {
      if (id === 4) {
        this.$message.success('退出成功')
        // this.reload()
        var time = setTimeout(() => {
          localStorage.clear()
          this.$router.push({
            path: '/homemain',
            name: 'Homemain'
          })
          location.reload()
          clearInterval(time)
        }, 100)
        return
      }
      this.num = id
    }
  }
}
</script>
<style lang="less" scoped>
.view-account-setup {
  display: flex;
  .view-account-setup-left {
    width: 280px;
    height: 253px;
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 20px;

    .view-account-setup-a {
      flex: 1;
      color: #333;
      font-weight: 400;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      &:hover {
        font-weight: 600;
        border-left: 2px solid #cda156;
      }
    }
    .active {
      font-weight: 600;
      border-left: 2px solid #cda156;
    }
  }
}
</style>
