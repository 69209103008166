<template>
  <div class="view-account-setup-info">
    <div class="of-info">
      <i class="iconfont icon-tishishuoming"></i>
      <span>您在完善相关资料后，才能使用于拍卖有关的功能</span>
    </div>
    <div class="from-data">
      <el-form :model="getAuctionHouseRegisterDescData" :rules="rules" ref="getAuctionHouseRegisterDescData"
        label-width="170px" class="demo-ruleForm">
        <el-form-item label="LOGO" prop="logo">
          <div class="upload-img">
            <el-upload action="" list-type="picture-card" ref="uploadRef" :on-preview="handlePictureCardPreview"
              multiple :limit="1" :on-exceed="handleExceedTwo" :http-request='httpRequest' :on-remove="removeChange"
              :on-change="getFileOne" :file-list="fileListOne" :class="{hide:hideUploadEdit}">
              <i class="el-icon-plus"></i>
            </el-upload>
            <el-dialog :visible.sync="dialogVisible">
              <img width="100%" :src="dialogImageUrl" alt="">
            </el-dialog>
            <span>您上传的Logo大小不超过3MB</span>
          </div>
        </el-form-item>
        <el-form-item label="公司名称" prop="corporateName">
          <el-input disabled v-model="getAuctionHouseRegisterDescData.corporateName" placeholder="请填写公司名称">
          </el-input>
        </el-form-item>
        <el-form-item label="联系人" prop="contacts">
          <el-input v-model="getAuctionHouseRegisterDescData.contacts" placeholder="请填写联系人"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input v-model="getAuctionHouseRegisterDescData.phone" placeholder="请填写联系电话"></el-input>
        </el-form-item>
        <el-form-item label="所在地区" prop="country">
          <div class="el-dis">
            <div class="el-select-box">
              <el-select v-model="getAuctionHouseRegisterDescData.country" @change="changeOne($event)"
                placeholder="请选择">
                <el-option v-for="item in auctionAreaListData" :key="item.code" :label="item.name" :value="item.code">
                </el-option>
              </el-select>
            </div>
            <div class="el-select-box" v-if="selectShow">
              <el-select v-model="getAuctionHouseRegisterDescData.location" @change="changeTwo" placeholder="请选择">
                <el-option v-for="item in auctionAreaListDataTwo" :key="item.code" :label="item.name"
                  :value="{value:item.code,label:item.name}">
                </el-option>
              </el-select>
            </div>
            <div class="el-select-box" v-if="selectShow">
              <el-select v-model="locationTwo" @change="changeShtree" placeholder="请选择">
                <el-option v-for="item in auctionAreaListSthreeData" :key="item.code" :label="item.name"
                  :value="{value:item.code,label:item.name}">
                </el-option>
              </el-select>
            </div>
          </div>
        </el-form-item>
        <el-form-item label="详细地址" prop="detailedAddress">
          <el-input type="textarea" v-model="getAuctionHouseRegisterDescData.detailedAddress" placeholder="请填写您的详细地址">
          </el-input>
        </el-form-item>
        <el-form-item label="官方网站" prop="website">
          <el-input v-model="getAuctionHouseRegisterDescData.website" placeholder="请填写官方网站"></el-input>
        </el-form-item>
        <el-form-item label="开户行" prop="bankOfDeposit">
          <el-input v-model="getAuctionHouseRegisterDescData.bankOfDeposit" placeholder="请填写开户行"></el-input>
        </el-form-item>
        <el-form-item label="收款账号" prop="collectionAccountNumber">
          <el-input v-model="getAuctionHouseRegisterDescData.collectionAccountNumber" placeholder="请填写收款账号">
          </el-input>
        </el-form-item>
        <el-form-item label="拍卖行简介" prop="introduction">
          <el-input type="textarea" v-model="getAuctionHouseRegisterDescData.introduction" placeholder="请填写拍卖行简介">
          </el-input>
        </el-form-item>
        <el-form-item label="拍卖行条款" prop="terms">
          <el-input type="textarea" v-model="getAuctionHouseRegisterDescData.terms" placeholder="请填写拍卖行条款">
          </el-input>
          <!-- <div class="textarea-box">
            <textarea name="" placeholder="" class="textarea"
              v-model="getAuctionHouseRegisterDescData.terms"></textarea>
          </div> -->
        </el-form-item>
      </el-form>
    </div>
    <div class="submit-save">
      <button class="go-one" @click="submit('getAuctionHouseRegisterDescData')">保存</button>
    </div>
  </div>
</template>
<script>
import { getAuctionHouseRegisterDesc, auctionAreaList, uploadFile, setAuctionHouseEssentialInformation } from '../../../../api/index'
import OSS from 'ali-oss'
export default {
  name: 'Accountsetupinfo',
  data () {
    return {
      getAuctionHouseRegisterDescData: {},
      rules: {
        logo: [
          { required: true, message: '请上传logo', trigger: 'change' }
        ],
        corporateName: [
          { required: true, message: '请输入公司名称', trigger: 'change' }
        ],
        contacts: [
          { required: true, message: '请输入联系人', trigger: 'change' }
        ],
        phone: [
          { required: true, message: '请输入联系电话', trigger: 'change' }
        ],
        detailedAddress: [
          { required: true, message: '请输入详细地址', trigger: 'change' }
        ],
        terms: [
          { required: true, message: '请输入拍卖行条款', trigger: 'blur' }
        ],
        country: [
          { required: true, message: '请选择所在地区', trigger: 'blur' }
        ]
      },
      selectShow: true,
      countyTxtCode: '',
      countyTxt: '',
      countyTxtTwo: '',
      countyTxtStree: '',
      auctionAreaListData: [],
      auctionAreaListDataTwo: [],
      auctionAreaListSthreeData: [],
      fileListOne: [],
      dialogImageUrl: '',
      dialogVisible: false,
      x_token: {
        x_user_id: Number(localStorage.getItem('accessId')) || 0,
        authorization: localStorage.getItem('accessToken')
      },
      locationTwo: '',

      location: '',
      ossToken: '',
      hideUploadEdit: false
    }
  },
  created () {
    this.auctionAreaList()
    this.getAuctionHouseRegisterDesc()
    this.auctionAreaListTwo()
  },
  watch: {
    countyTxtTwo: {
      handler (newV, oldV) {
        if (newV !== oldV) {
          this.locationTwo = ''
        }
      }

    }
  },
  methods: {
    async getAuctionHouseRegisterDesc () {
      const res = await getAuctionHouseRegisterDesc({
        userId: Number(localStorage.getItem('accessId')),
        x_user_id: Number(localStorage.getItem('accessId')),
        authorization: localStorage.getItem('accessToken')
      })
      if (res.code === 0) {
        this.fileListOne = []
        this.getAuctionHouseRegisterDescData = res.data
        localStorage.setItem('mailingMethod', this.getAuctionHouseRegisterDescData.mailingMethod)
        localStorage.setItem('paymentMethod', this.getAuctionHouseRegisterDescData.paymentMethod)
        if (this.getAuctionHouseRegisterDescData.logo !== '') {
          this.fileListOne.push({
            name: 'logo',
            url: this.getAuctionHouseRegisterDescData.logo
          })
          this.hideUploadEdit = true
        }
        if (this.getAuctionHouseRegisterDescData.country !== 0) {
          this.selectShow = false
        }
        // const getAuctionHouseRegisterDescData = Object.assign(this.getAuctionHouseRegisterDescData, this.locationTwo)
        // this.getAuctionHouseRegisterDescData = getAuctionHouseRegisterDescData
        const county = this.getAuctionHouseRegisterDescData.location.split(',')
        if (county[1] === 'undefined') {
          county[1] = ''
        }
        this.getAuctionHouseRegisterDescData.location = county[0]
        this.locationTwo = county[1]
      } else {
        this.$message.error(res.msg)
      }
    },
    // 保存
    submit (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const data = Object.assign(this.getAuctionHouseRegisterDescData, this.x_token)
          this.getAuctionHouseRegisterDescData.location = this.location || this.getAuctionHouseRegisterDescData.location + ',' + this.locationTwo
          const res = await setAuctionHouseEssentialInformation(data)
          if (res.resultCode === 0) {
            this.$message.success('保存成功')
            this.getAuctionHouseRegisterDesc()
          } else {
            this.$message.error(res.msg)
          }
        } else {
          return false
        }
      })
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    httpRequest () {

    },
    handleExceedTwo (files, fileList) {
      this.$message.warning('当前限制上传 1 个文件')
    },
    // 获取所属国家
    async auctionAreaList () {
      const res = await auctionAreaList({
        code: ''
      })
      if (res.code === 0) {
        this.auctionAreaListData = res.data.newList
      } else {
        this.$message.error(res.msg)
      }
    },
    async auctionAreaListTwo (id) {
      if (id === 0) {
        id = '000'
      }
      const res = await auctionAreaList({
        code: id || '000'
      })
      if (res.code === 0) {
        this.auctionAreaListDataTwo = res.data.list
      } else {
        this.$message.error(res.msg)
      }
    },
    async auctionAreaListSthree (id) {
      const res = await auctionAreaList({
        code: id.toString()
      })
      if (res.code === 0) {
        this.auctionAreaListSthreeData = res.data.list
      } else {
        this.$message.error(res.msg)
      }
    },
    // 选择第一个国家
    changeOne (params) {
      // this.countyTxtCode = value
      if (params !== 0) {
        this.selectShow = false
      } else {
        this.selectShow = true
        this.auctionAreaListTwo(params)
      }
    },
    changeTwo (params) {
      const { value, label } = params
      if (this.selectShow === true) {
        this.auctionAreaListSthree(value)
        this.countyTxtTwo = label
        this.location = this.countyTxtTwo
      }
    },
    changeShtree (params) {
      const { value, label } = params
      this.countyTxtStree = label
      this.getAuctionHouseRegisterDescData.locationTwo = label
      this.location = this.countyTxtTwo + ',' + this.countyTxtStree
    },
    // oss token
    async uploadFile () {
      const res = await uploadFile()
      if (res.code === 0) {
        this.ossToken = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    removeChange (file, fileList) {
      this.getAuctionHouseRegisterDescData.logo = ''
      this.$refs.getAuctionHouseRegisterDescData.validateField('logo')
      this.hideUploadEdit = false
    },
    // 上传logo
    async getFileOne (event, fileList) {
      const isLt300K = event.raw.size / 1024 / 1024 < 3
      if (!isLt300K) {
        this.$message.error('上传图片大小不能超过3M!')
        this.fileListOne = []
        return
      }
      const isTypeTrue = /^image\/(jpeg|png|jpg)$/.test(event.raw.type)
      if (!isTypeTrue) {
        this.$message.error('上传图片格式不对!')
        this.fileListOne = []
        return
      }
      var uploadHost = 'https://pic.easyebid.com'
      await this.uploadFile()
      var client = new OSS({
        region: 'oss-cn-beijing',
        accessKeyId: this.ossToken.accessKeyId,
        accessKeySecret: this.ossToken.accessKeySecret,
        bucket: 'yideonline',
        stsToken: this.ossToken.securityToken
      })
      var file = event.raw
      const type = file.name.split('.')
      var timestamp = Date.parse(new Date())
      const len = type.length
      const fileName = 'yidelogo' + timestamp + '.' + type[len - 1]
      const result = await client.put(`logo/${fileName}`, file)
      this.getAuctionHouseRegisterDescData.logo = `${uploadHost}/${result.name}`
      this.$refs.getAuctionHouseRegisterDescData.validateField('logo')
      const vm = this
      vm.hideUploadEdit = fileList.length >= 1
    }
  }

}
</script>
<style lang="less" scoped>
.view-account-setup-info {
  background: #fff;
  width: 100%;
  padding: 10px 20px 30px 20px;
  margin-bottom: 60px;
  /deep/ .hide .el-upload--picture-card {
    display: none;
  }
  /deep/ .el-textarea__inner {
    width: 600px;
    height: 100px;
    resize: none;
    outline: none;
    border: 1px solid #bfc3c4;
    padding-left: 13px;
  }
  .of-info {
    height: 50px;
    background: #f5edde;
    opacity: 0.56;
    display: flex;
    align-items: center;
    padding-left: 30px;
    span {
      color: #6e5121;
      font-size: 18px;
      padding-left: 5px;
    }
    i {
      color: #cda156;
      font-size: 18px;
    }
  }
  .from-data {
    margin-top: 30px;
    padding-left: 200px;
    /deep/ .el-form-item__label {
      font-weight: 600;
      color: rgb(56, 55, 55);
      font-size: 16px;
    }
    /deep/ .el-form-item {
      display: flex;
      align-items: center;
    }
    /deep/ .el-form-item__content {
      margin-left: 0 !important;
    }
    /deep/ .el-input__inner {
      height: 50px;
      border: 1px solid #bfc3c4;
      background: #ffffff;
      color: #333;
      font-size: 16px;
      width: 600px;
    }
    /deep/ .el-upload-list--picture-card .el-upload-list__item {
      width: 120px;
      height: 80px;
    }
    .el-dis {
      display: flex;
      justify-content: space-between;
      width: 600px;
      .el-select-box {
        width: 195px;
        /deep/ .el-input__inner {
          width: 195px;
        }
      }
    }
    .textarea-box {
      width: 600px;
      .textarea {
        width: 100%;
        height: 100px;
        resize: none;
        outline: none;
        border: 1px solid #bfc3c4;
        padding-left: 13px;
      }
    }
    .upload-img {
      display: flex;
      align-items: center;
      span {
        padding-left: 20px;
      }
      /deep/ .el-upload--picture-card {
        width: 120px;
        height: 80px;
        background: #fff;
        border: 1px solid #bfc3c4;
        line-height: 80px;
      }
      /deep/ .el-icon-plus {
        background: url("../../../../assets/images/shangchuantupian备份 2@2x.png")
          no-repeat;
        background-size: 27px;
      }
      /deep/ .el-upload--picture-card i {
        color: transparent;
      }
    }
  }
  .submit-save {
    .go-one {
      width: 280px;
      color: #6e5121;
      font-size: 18px;
      opacity: 0.7;
      border-radius: 8px;
      background: #cda156;
      height: 44px;
      text-align: center;
      line-height: 44px;
      border: none;
      float: left;
      margin-left: 430px;
    }
  }
}
</style>
