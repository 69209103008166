<template>
  <div class="view-account-information">
    <div class="information-show">
      <p class="information-left">用户名</p>
      <p class="information-right">{{getAuctionHouseRegisterDescData.userName}}</p>
    </div>
    <div class="information-show">
      <p class="information-left">邮箱</p>
      <p class="information-right">{{getAuctionHouseRegisterDescData.email}}</p>
    </div>
    <div class="information-show">
      <p class="information-left">密码</p>
      <p class="information-right w">*************</p>
      <a href="javascript:;" class="information-right tr" @click="editPassword">
        <span class="pr">修改密码</span>
        <i class="iconfont icon-bianji"></i>
      </a>
    </div>
    <el-dialog title="修改密码" :visible.sync="dialogVisible" width="30%">
      <el-form :model="setAuctionHousePasswordInformationData" :rules="rules"
        ref="setAuctionHousePasswordInformationData" label-width="100px" class="demo-ruleForm">
        <el-form-item label="原密码" prop="oldPassword">
          <el-input v-model="setAuctionHousePasswordInformationData.oldPassword" placeholder="请输入原密码"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="passwordOk">
          <el-input v-model="setAuctionHousePasswordInformationData.passwordOk" type="password" placeholder="请输入新密码">
          </el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="password">
          <el-input v-model="setAuctionHousePasswordInformationData.password" type="password" placeholder="请再次输入新密码">
          </el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="code">
          <div class="ident-code">
            <el-input v-model="setAuctionHousePasswordInformationData.code" placeholder="请填写验证码">
            </el-input>
            <!-- 验证码组件 -->
            <s-identify :identifyCode="identifyCode"></s-identify>
          </div>

        </el-form-item>
      </el-form>
      <el-button type="primary" @click="saveOk('setAuctionHousePasswordInformationData')">确 定</el-button>
    </el-dialog>
  </div>
</template>
<script>
import { getAuctionHouseRegisterDesc, setAuctionHousePasswordInformation } from '../../../../api/index'
import SIdentify from '@/components/identify'
export default {
  name: 'AccountInformation',
  components: {
    SIdentify
  },
  data () {
    var validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'))
      } else {
        if (this.setAuctionHousePasswordInformationData.password !== '') {
          this.$refs.setAuctionHousePasswordInformationData.validateField('password')
        }
        callback()
      }
    }
    var validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'))
      } else if (value !== this.setAuctionHousePasswordInformationData.passwordOk) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    }
    const validateVerifycode = (rule, value, callback) => {
      const newVal = value.toLowerCase()
      const identifyStr = this.identifyCode.toLowerCase()
      if (newVal === '') {
        callback(new Error('请输入验证码'))
      } else if (newVal !== identifyStr) {
        callback(new Error('验证码不正确!'))
      } else {
        callback()
      }
    }
    return {
      getAuctionHouseRegisterDescData: {},
      dialogVisible: false,
      setAuctionHousePasswordInformationData: {
        id: Number(localStorage.getItem('accessId')),
        oldPassword: '',
        password: '',
        passwordOk: '',
        code: '',
        x_user_id: Number(localStorage.getItem('accessId')) || 0,
        authorization: localStorage.getItem('accessToken')
      },
      identifyCodes: 'ABCDEFGHGKMNPQRSTUVWXY',
      identifyCode: '',
      rules: {
        oldPassword: [
          { required: true, message: '请输入原密码', trigger: 'change' }
        ],
        passwordOk: [
          { required: true, validator: validatePass, trigger: 'change' },
          { min: 8, max: 16, message: '密码长度不正确', trigger: 'change' }
        ],
        password: [
          { required: true, validator: validatePass2, trigger: 'change' },
          { min: 8, max: 16, message: '密码长度不正确', trigger: 'change' }
        ],
        code: [
          { required: true, trigger: 'change', validator: validateVerifycode }
        ]
      }
    }
  },
  created () {
    this.getAuctionHouseRegisterDesc()
  },
  mounted () {
    // 验证码初始化
    this.identifyCode = ''
    this.makeCode(this.identifyCodes, 4)
  },
  methods: {
    async getAuctionHouseRegisterDesc () {
      const res = await getAuctionHouseRegisterDesc({
        authorization: localStorage.getItem('accessToken'),
        x_user_id: Number(localStorage.getItem('accessId')) || 0,
        userId: Number(localStorage.getItem('accessId'))
      })
      if (res.code === 0) {
        this.getAuctionHouseRegisterDescData = res.data
      } else {
        this.$message.error(res.msg)
      }
    },
    editPassword () {
      this.dialogVisible = true
      this.setAuctionHousePasswordInformationData.oldPassword = ''
      this.setAuctionHousePasswordInformationData.password = ''
      this.setAuctionHousePasswordInformationData.passwordOk = ''
      this.setAuctionHousePasswordInformationData.code = ''
      this.refreshCode()
    },
    async saveOk (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          var testPassword = /(?![A-Z]*$)(?![a-z]*$)(?![0-9]*$)(?![^a-zA-Z0-9]*$)/
          if (!testPassword.test(this.setAuctionHousePasswordInformationData.password)) {
            this.$message.error('密码要求为8-16位数字、英文字母、特殊符号中至少两种类型以上的组合')
            return
          }
          this.setAuctionHousePasswordInformationData.oldPassword = this.$md5(this.setAuctionHousePasswordInformationData.oldPassword)
          this.setAuctionHousePasswordInformationData.password = this.$md5(this.setAuctionHousePasswordInformationData.password)
          this.setAuctionHousePasswordInformationData.passwordOk = this.$md5(this.setAuctionHousePasswordInformationData.passwordOk)
          const res = await setAuctionHousePasswordInformation(this.setAuctionHousePasswordInformationData)
          if (res.resultCode === 0) {
            this.$message.success('修改成功')
            this.dialogVisible = false
            var timer = setTimeout(() => {
              location.reload()
              localStorage.clear()
              clearInterval(timer)
            }, 300)
          } else {
            this.$message.error(res.msg)
          }
        } else {
          return false
        }
      })
    },
    // 生成随机数
    randomNum (min, max) {
      return Math.floor(Math.random() * (max - min) + min)
    },
    // 切换验证码
    refreshCode () {
      this.identifyCode = ''
      this.makeCode(this.identifyCodes, 4)
    },
    // 生成四位随机验证码
    makeCode (o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode += this.identifyCodes[
          this.randomNum(0, this.identifyCodes.length)
        ]
      }
    }
  }
}
</script>
<style lang="less" scoped>
.view-account-information {
  background: #fff;
  width: 100%;
  padding: 0 120px;
  display: flex;
  flex-direction: column;
  height: 295px;
  margin-bottom: 60px;
  /deep/ .el-input__inner {
    width: 385px;
  }
  /deep/ .el-dialog__wrapper {
    z-index: 200 !important;
  }
  /deep/ .el-form-item__label {
    font-size: 18px;
    font-weight: 600;
  }
  /deep/ .el-button {
    width: 320px;
    background: #f5edde;
    border: none;
    height: 44px;
  }
  /deep/ .el-button--primary {
    color: #6e5121;
    font-weight: 600;
    font-size: 18px;
  }
  .ident-code {
    display: flex;
    /deep/ .el-input__inner {
      width: 247px;
    }
    /deep/ .el-input {
      width: 67%;
    }
  }
  .information-show {
    border-bottom: 1px solid #f3f3f3;
    width: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    padding-left: 20px;
    .information-left {
      color: #333;
      font-weight: 600;
      font-size: 20px;
      width: 100px;
      margin-bottom: 0;
      text-align: left;
    }
    .information-right {
      margin-bottom: 0;
      width: 300px;
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      color: #333;
      .pr {
        padding-right: 5px;
      }
    }
    .w {
      width: 800px;
    }
    .tr {
      text-align: right;
      color: #6e5121;
    }
  }
}
</style>
