<template>
  <div>
    <el-form ref="value" :rules="rules" :model="value" label-width="170px">
      <el-form-item label="佣金设置" prop="buyersPremium">
        <div class="heard">
          <p class="name cell-b">价格区间</p>
          <p class="name cell-b">竞价阶梯</p>
        </div>
        <div class="box-input">
          <!-- 左侧区间 -->
          <div class="input-box-one list">
            <div class="input" v-for="(i, index) in value.buyersPremium.min" :key="index">
              <el-input @input="onTxtInput1(index)" v-model="i.content" placeholder="" :readonly="index == 0 || index == value.buyersPremium.min.length - 1">
              </el-input>
            </div>
            <div class="span-box">
              <span class="pointer" @click="addData(1)">加区间</span>
              <span class="pointer" @click="reductionData(2)" style="padding-left:20px">减区间</span>
            </div>
          </div>
          <!-- 中间 -->
          <div class="icon-box"><img v-for="(i, index) in value.iconPriceList" :key="i" src="../assets/images/arrow.png" />
          </div>
          <!-- 右侧区间 -->
          <div class="input-box-two list">
            <div class="input" v-for="(i, index) in value.buyersPremium.max" :key="index">
              <el-input @input="onTxtInput2(index)" v-model="i.content" placeholder=""></el-input>
              <el-checkbox class="check-box-tax" v-model="i.checked">
                +TAX</el-checkbox>
            </div>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="竞价阶梯" prop="bidding">
        <div class="heard">
          <p class="name cell-b">区间</p>
          <p class="name cell-b">价格</p>
        </div>
        <div class="box-input">
          <!-- 左侧区间 -->
          <div class="input-box-one list">
            <div class="input" v-for="(i, index) in value.bidding.min" :key="index">
              <el-input @input="onTxtInput3(index)" v-model="i.content" placeholder="" :readonly="index == 0 || index == value.bidding.min.length - 1">
              </el-input>
            </div>
            <div class="span-box">
              <span class="pointer" @click="addData(3)">加区间</span>
              <span class="pointer" @click="reductionData(4)" style="padding-left:20px">减区间</span>
            </div>
          </div>
          <!-- 中间 -->
          <div class="icon-box"><img v-for="(i, index) in value.iconList" :key="i" src="../assets/images/arrow.png" />
          </div>
          <!-- 右侧区间 -->
          <div class="input-box-two list">
            <div class="input" v-for="(i, index) in value.bidding.max" :key="index">
              <div class="cell-input" v-for="(val, ind) in i.data" :key="ind">
                <el-input @input="onTxtInput4(index,ind)" v-model="val.content" placeholder=""></el-input>
                <i v-show="val.iconShow" class="el-icon-minus i-s"></i>
              </div>
              <i v-show="i.data.length < 3" class="el-icon-plus i-l pointer" @click="addList(i.data)"></i>
              <i v-show="i.data.length > 1" class="el-icon-minus i-l pointer" @click="reductionList(i.data)"></i>
            </div>
          </div>

        </div>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: 'biddingLadder',
  data () {
    return {
      rules: {
        buyersPremium: [
          { required: true, message: '请填写佣金设置', trigger: 'change' }
        ],
        bidding: [
          { required: true, message: '请填写竞价阶梯', trigger: 'change' }
        ]
      }
    }
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    numReg (val) {
      // 千分号及两位小数限制
      // 句号转小数点
      val = val.replace(/[\u3002]/, '.')
      // 先把非数字的都替换掉，除了数字和.
      val = val.replace(/[^\d.]/g, '')
      // 必须保证第一个为数字而不是.
      val = val.replace(/^\./, '')
      // 第一位可以为0第二位不能输入数字
      val = val.replace(/^0\d/, '0')
      // 保证.只出现一次，而不能出现两次以上
      val = val
        .replace('.', '$#$')
        .replace(/\./g, '')
        .replace('$#$', '.')
      // 只能输入两个小数
      val = val.replace(/^(\-)*(\d+)\.(\d\d).*$/, '$1$2.$3')
      // 添加千分号
      return val.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    },
    numRegInt (val) {
      // 千分号整数
      // 先把非数字的都替换掉，除了数字和
      val = val.replace(/[^\d]/g, '')
      // 第一位可以为0第二位不能输入数字
      val = val.replace(/^0\d/, '0')
      // 添加千分号
      return val.replace(/(\d{1,3})(?=(\d{3})+(?:$|\.))/g, '$1,')
    },
    onTxtInput1 (index) {
      const val2 = this.numRegInt(this.value.buyersPremium.min[index].content)
      this.value.buyersPremium.min[index].content = val2
    },
    onTxtInput2 (index) {
      const val2 = this.numReg(this.value.buyersPremium.max[index].content)
      this.value.buyersPremium.max[index].content = val2
    },
    onTxtInput3 (index) {
      const val2 = this.numRegInt(this.value.bidding.min[index].content)
      this.value.bidding.min[index].content = val2
    },
    onTxtInput4 (index, index2) {
      const val2 = this.numRegInt(
        this.value.bidding.max[index].data[index2].content
      )
      this.value.bidding.max[index].data[index2].content = val2
    },
    // 加区间
    addData (index) {
      if (index === 3) {
        // 竞价阶梯价区间
        this.value.iconList += 1
        var len = this.value.bidding.min.length
        var lens = this.value.bidding.max.length
        if (this.value.bidding.min[len - 1].content === '∞') {
          this.value.bidding.min[len - 1].content = ''
        }
        this.value.bidding.min.push({
          index: len - 1,
          content: '∞'
        })
        this.value.bidding.max.push({
          index: lens - 1,
          data: [
            {
              content: ''
            }
          ]
        })
      } else if (index === 1) {
        // 佣金设置加区间
        this.value.iconPriceList += 1
        var lent = this.value.buyersPremium.min.length
        var lenP = this.value.buyersPremium.max.length
        if (this.value.buyersPremium.min[lent - 1].content === '∞') {
          this.value.buyersPremium.min[lent - 1].content = ''
        }
        this.value.buyersPremium.min.push({
          index: lent - 1,
          content: '∞'
        })
        this.value.buyersPremium.max.push({
          index: lenP - 1,
          content: '',
          checked: false
        })
      }
    },
    // 减区间
    reductionData (index) {
      if (index === 4) {
        // 竞价阶梯减区间
        // var len = this.inputOne.length;
        if (
          this.value.bidding.min.length === 2 ||
          this.value.bidding.max === 1
        ) {
          return
        }
        this.value.iconList -= 1
        this.value.bidding.min.pop()
        this.value.bidding.max.pop()
        var len = this.value.bidding.min.length
        // if(this.inputOne[len - 1].content == ''){
        this.value.bidding.min[len - 1].content = '∞'
        // }
      } else if (index === 2) {
        // 佣金设置减区间
        if (
          this.value.buyersPremium.min.length === 2 ||
          this.value.buyersPremium.max.length === 1
        ) {
          return
        }
        this.value.iconPriceList -= 1
        this.value.buyersPremium.min.pop()
        this.value.buyersPremium.max.pop()
        var lent = this.value.buyersPremium.min.length
        this.value.buyersPremium.min[lent - 1].content = '∞'
      }
    },
    // 竞价阶梯加价格输入框
    addList (data) {
      var len = data.length
      data[len - 1].iconShow = true
      data.push({
        content: '',
        iconShow: false
      })
      this.$forceUpdate()
    },
    // 竞价阶梯减价格输入框
    reductionList (data) {
      data.pop()
      var len = data.length
      data[len - 1].iconShow = false
      this.$forceUpdate()
    }
  }
}
</script>
<style lang="less" scoped>
/deep/ .el-input {
  width: 150px !important;
}
/deep/ .el-input__inner {
  width: 150px !important;
}

.heard {
  width: 610px;
  height: 30px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #f0f0f0;

  .cell-b {
    width: 100px;
    height: 35px;
    text-align: center;
    line-height: 35px;
    margin-bottom: 0;
    font-size: 16px;
  }

  .title {
    background-color: #ffffff;
    color: #000000;
    line-height: 43px;
  }

  .name {
    color: #919191;
  }

  .name:nth-child(2) {
    margin-right: 70px;
  }
}

.box-input {
  width: calc(100% - 100px);
  display: flex;
  justify-content: flex-start;
  // align-items: center;

  .list {
    .input {
      margin: 20px 0 0 0;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      /deep/.el-input {
        width: 122px;
        height: 38px;
        border: 1px solid #ccc;
        background: #fff;
        border-radius: 5px;
        input {
          text-align: center;
        }
      }

      .cell-input {
        display: flex;
        justify-content: flex-start;
        align-items: center;
      }
      i {
        color: #c4c6cb;
        margin: 0 6px;
      }

      .i-s {
        font-size: 13px;
      }
      .i-l {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .icon-box {
    width: 60px;
    padding: 35px 0 0 0;

    img {
      width: 45px;
      height: 45px;
      margin-top: 14px;
    }
  }

  .input-box-two {
    padding: 35px 0 0 0;

    .input {
      margin-top: 22px;
      .check-box-tax {
        padding-left: 30px;
      }
    }

    .input:first-child {
      margin-top: 15px;
    }
  }

  .span-box {
    margin-top: 15px;
    display: flex;
    // justify-content: space-around;
    align-items: center;

    span {
      font-size: 14px;
      color: #666;
    }
  }
}
</style>
